import React, { memo, useMemo } from 'react'
import ReactPlayer from 'react-player'
import BaseSubtask from './BaseSubtask'
import { WidgetIcon } from 'components/TasksV2/UI/WidgetIcon'
import { Subtask } from 'constants/interfaces'
import keys from 'constants/keys'
import { useResults } from 'components/TasksV2/hooks/useResults'
import { useTaskOverviewContext } from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'

interface IProps {
  subtask: Subtask
}

export const VideoSubtask: React.FC<IProps> = memo(({ subtask }) => {
  const { resultContextId } = useTaskOverviewContext()
  const subTaskResult = useResults({ subtask, resultContextId })

  const firstVideoResult = subTaskResult?.videos?.[0] ?? ''
  const isSubtaskDone = subTaskResult?.status === keys.TASK_STATUSES.DONE.value

  const renderContent = useMemo(
    () =>
      firstVideoResult ? (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            top: '-30px',
            boxShadow: '0px 1px 3px 1px #949191',
            width: '180px',
            height: '70px',
            borderRadius: '15px',
            overflow: 'hidden',
          }}
        >
          <ReactPlayer
            url={firstVideoResult}
            playing={false}
            className="react-player"
            muted
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />
        </div>
      ) : (
        <WidgetIcon subtaskType={subtask.type} />
      ),
    [firstVideoResult, subtask.type]
  )

  return (
    <BaseSubtask
      renderContent={renderContent}
      subTaskResult={subTaskResult}
      subtask={subtask}
      isSubtaskDone={isSubtaskDone}
    />
  )
})
