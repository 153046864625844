import React, { useCallback, useEffect, useRef } from 'react'
import { Stack } from '@chakra-ui/core'
import TaskTitle from './TaskTitle'
import Description from './Description'
import SubtasksList from './SubtasksList'
import { StepType, Subtask, useFormStepContext } from '../../FormStepContext'
import { useFieldArray, useForm, FormContext } from 'react-hook-form'
import { SubtasksValidationMessage } from './SubtasksValidationMessage'

const SubtaskCreationStep: React.FC = () => {
  const { setCurrentFormSubmit, updateFormData, formData, setSubtaskActions, setStepStack } = useFormStepContext()

  const defaultValues = formData[StepType.SUBTASK_TASK_CREATION] || {
    title: '',
    description: { desc: '', attached_images: [], attached_files: [], attached_videos: [] },
    sub_tasks: [],
  }

  const methods = useForm({
    defaultValues,
  })

  const { control, handleSubmit, errors } = methods

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'sub_tasks',
  }) as unknown as {
    fields: Subtask[]
    append: (data: Subtask) => void
    remove: (index: number) => void
    move: (from: number, to: number) => void
  }

  const scrollToFirstError = useCallback(() => {
    const firstErrorIndex = fields.findIndex((_, index) => errors.sub_tasks?.[index])
    if (firstErrorIndex !== -1 && widgetRefs.current[firstErrorIndex]) {
      widgetRefs.current[firstErrorIndex]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [errors.sub_tasks, fields])

  const scrollToLastSubtask = useCallback(() => {
    const scrollContainer = document.querySelector('[data-scroll-container]') as HTMLDivElement
    const lastIndex = fields.length - 1

    if (scrollContainer && lastIndex >= 0 && widgetRefs.current[lastIndex]) {
      const lastSubtask = widgetRefs.current[lastIndex]
      if (lastSubtask) {
        const subtaskBottom = lastSubtask.offsetTop + lastSubtask.offsetHeight
        const containerVisibleBottom = scrollContainer.scrollTop + scrollContainer.offsetHeight

        if (subtaskBottom > containerVisibleBottom) {
          scrollContainer.scrollTo({
            top: subtaskBottom - scrollContainer.offsetHeight + 200,
            behavior: 'smooth',
          })
        }
      }
    }
  }, [fields.length])

  const validationMessageRef = useRef<{ triggerShake: () => void }>(null)

  useEffect(() => {
    if (fields.length > 0) {
      scrollToLastSubtask()
    }
  }, [fields.length, scrollToLastSubtask])

  useEffect(() => {
    setCurrentFormSubmit(() =>
      handleSubmit((data) => {
        if (fields.length === 0) {
          validationMessageRef.current?.triggerShake()
          return
        }
        updateFormData(StepType.SUBTASK_TASK_CREATION, data)
        setStepStack((prev) => [...prev, StepType.TASK_SETTINGS])
      })
    )
    setSubtaskActions({ append, remove, move })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError()
    }
  }, [errors, scrollToFirstError])

  const widgetRefs = useRef<(HTMLDivElement | null)[]>([])

  return (
    <FormContext {...methods}>
      <Stack spacing={4} mt={4}>
        <TaskTitle ref={(el) => (widgetRefs.current[0] = el)} />
        <Description />

        <SubtasksList widgetRefs={widgetRefs} fields={fields} />
      </Stack>

      <SubtasksValidationMessage ref={validationMessageRef} fields={fields} />
    </FormContext>
  )
}

export default SubtaskCreationStep
