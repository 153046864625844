import React from 'react'
import { Subtask } from 'constants/interfaces'
import { useTaskOverviewContext } from '../../TaskOverviewStackProvider'
import { VotersList } from './VotersList'

interface IProps {
  pollSubtask: Subtask
}
const VotesOverview: React.FC<IProps> = ({ pollSubtask }) => {
  const { resultContextId } = useTaskOverviewContext()

  return <VotersList pollSubtask={pollSubtask} resultContextId={resultContextId} />
}

export default VotesOverview
