import React from 'react'
import { RootState, Subtask } from 'constants/interfaces'
import { Divider, Flex, Icon, Stack, Text } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import useLocaleText from 'components/useLocaleText'
import { MdOutlineStar } from 'react-icons/md'
import { VotedParticipants } from './VotedParticipicans'
import { extractedUidsFromGroups } from 'utils'
import { isRtlSelector } from 'redux/selectors/general'
import { Title } from 'components/TasksV2/UI/Title'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { buildPollOptions } from 'components/TasksV2/TasksOverview/utils'
import { parseRecurrenceTimestamp, useTaskOverviewContext } from '../../TaskOverviewStackProvider'

interface IProps {
  pollSubtask: Subtask
  resultContextId: string
}
export const VotersList = ({ pollSubtask, resultContextId }: IProps) => {
  const { options, tid, st_id } = pollSubtask
  const { task } = useTaskOverviewContext()

  const t_votes = useLocaleText('t_votes')
  const t_do_not_voted = useLocaleText('t_do_not_voted')
  const isRtl = useSelector(isRtlSelector)
  const { allResults } = useSelector((state: RootState) => state.tasks_v2)
  const {
    config: { groups: retailGroups, tags: retailTags, retailUsersObject },
    userWriteTaskPermissions: { users: permittedUsers, groups: permittedGroups },
  } = useSelector((state: RootState) => state.config)
  const { tags, groups, users, is_individual_results, recurrence_ts } = task

  if (!options) return null

  const taskTags = retailTags.filter((tag) => tags?.includes(tag.sk))
  const taskTagUsers = taskTags.map((tag) => tag?.users ?? []).flat()
  const taskTagGroups = taskTags.map((tag) => tag?.groups ?? []).flat()

  const possibleVoters = !is_individual_results
    ? Array.from(new Set([...(groups ?? []), ...(users ?? []), ...taskTagGroups, ...taskTagUsers])).filter(
        (id) => Object.keys(retailUsersObject!).includes(id) || Object.keys(retailGroups).includes(id)
      )
    : (
        Array.from(
          new Set([
            ...users,
            ...taskTagUsers,
            ...extractedUidsFromGroups(groups ?? [], retailGroups),
            ...extractedUidsFromGroups(taskTagGroups, retailGroups),
          ])
        ) as string[]
      ).filter((id) => Object.keys(retailUsersObject!).includes(id) || Object.keys(retailGroups).includes(id))

  const currentPollResults =
    allResults[tid]?.filter((result) => {
      const recurrenceUnix = recurrence_ts ? parseRecurrenceTimestamp(recurrence_ts) : null
      const createdAtCheck =
        result?.created_at_ts && recurrenceUnix !== null ? Number(result.created_at_ts) >= recurrenceUnix : true
      return result.st_id === st_id && createdAtCheck
    }) ?? []

  const votedParticipants = currentPollResults
    ?.filter((result) => result?.poll_selections && result?.poll_selections?.length > 0)
    .map((result) => result.context_id)

  const notVotedParticipants = possibleVoters.filter((participant) => !votedParticipants?.includes(participant))
  const optionsArray = buildPollOptions({
    options,
    pollResults: currentPollResults,
    permittedUsers,
    permittedGroups,
    groups: retailGroups,
    retailUsersObject: retailUsersObject!,
  })

  return (
    <Stack direction="column" spacing={4}>
      {optionsArray.map(({ label, count, currentQuestionParticipants }, index) => (
        <WidgetWrapper key={index} p="15px">
          <Flex
            flexDir="row"
            justifyContent="space-between"
            fontWeight="500"
            alignItems="center"
            style={{ columnGap: '10px' }}
          >
            <Title title={label} w="max-context" fontWeight="500" fontSize="16px" fontFamily="Arial" isTruncated />

            <Flex
              flexDir={isRtl ? 'row-reverse' : 'row'}
              style={{ columnGap: '5px' }}
              alignItems="center"
              fontSize="16px"
              fontFamily="Arial"
              w="max-context"
              justifyContent="flex-end"
            >
              {currentQuestionParticipants.find((participant) => participant.id === resultContextId) && (
                <Icon as={MdOutlineStar} />
              )}
              <Text fontFamily="Arial" ml={0}>
                {count}/{possibleVoters.length}
              </Text>
              <Text fontFamily="Arial">{t_votes}</Text>
            </Flex>
          </Flex>

          {currentQuestionParticipants.length > 0 && (
            <>
              <Divider />
              <VotedParticipants participants={currentQuestionParticipants} />
            </>
          )}
        </WidgetWrapper>
      ))}
      {notVotedParticipants.length > 0 && (
        <WidgetWrapper p="20px">
          <Flex flexDir="row" justifyContent="space-between" fontWeight="500" fontSize="18px" fontFamily="Arial">
            <Title
              title={t_do_not_voted}
              w="max-context"
              isTruncated
              fontWeight="500"
              fontFamily="Arial"
              fontSize="18px"
            />
            <Text fontWeight="500" fontSize="18px" fontFamily="Arial">
              {notVotedParticipants.length}
            </Text>
          </Flex>

          {notVotedParticipants.length > 0 && (
            <>
              <Divider />
              <VotedParticipants
                participants={notVotedParticipants.map((id) => ({
                  id,
                }))}
              />
            </>
          )}
        </WidgetWrapper>
      )}
    </Stack>
  )
}
