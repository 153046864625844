import { CameraIcon, LinkIcon, PollIcon, VideoIcon } from 'components/CommonComponents/JSXIcons'
import keys from 'constants/keys'
import React from 'react'

interface IWidgetIcon {
  subtaskType: number
}
export const WidgetIcon: React.FC<IWidgetIcon> = ({ subtaskType }) => {
  const renderIcon = () => {
    switch (subtaskType) {
      case keys.SUBTASK_TYPES.POLL_SUBTASK:
        return (
          <PollIcon pos="absolute" left="50%" transform="translateX(-50%)" top="-25px" width="50px" height="50px" />
        )
      case keys.SUBTASK_TYPES.IMAGE_SUBTASK:
        return (
          <CameraIcon pos="absolute" left="50%" transform="translateX(-50%)" top="-25px" width="50px" height="50px" />
        )
      case keys.SUBTASK_TYPES.VIDEO_SUBTASKS:
        return (
          <VideoIcon pos="absolute" left="50%" transform="translateX(-50%)" top="-25px" width="50px" height="50px" />
        )
      case keys.SUBTASK_TYPES.LINK_SUBTASK:
        return (
          <LinkIcon pos="absolute" left="50%" transform="translateX(-50%)" top="-25px" width="50px" height="50px" />
        )
      default:
        return null
    }
  }

  return <>{renderIcon()}</>
}
