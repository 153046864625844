import React from 'react'
import { Box, Flex, FlexProps } from '@chakra-ui/core'
import { ErrorMessage } from './ErrorMessage'

interface IProps extends FlexProps {
  isDragging?: boolean
  children: React.ReactNode
  isError?: boolean
  errorMessage?: string | null
}

export const WidgetWrapper: React.FC<IProps> = ({
  children,
  isError = false,
  isDragging = false,
  errorMessage,
  ...props
}) => {
  return (
    <Flex
      flexDir="column"
      background="white"
      borderRadius="10px"
      boxShadow={isError ? '0px 0px 0px 3px #EA9999' : ' 0px 2px 6px 0px  #dcdcdc'}
      transform={isDragging ? 'scale(1.2)' : 'scale(1)'}
      animation={isDragging ? 'jellyDragging 0.25s ease infinite' : undefined}
      {...props}
    >
      {children}
      {isError && errorMessage && (
        <Box px="10px">
          <ErrorMessage errorMessage={errorMessage} />
        </Box>
      )}
    </Flex>
  )
}

const styles = `
@keyframes jellyDragging {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-1deg);
  }
}
`

const styleSheet = document.createElement('style')
styleSheet.type = 'text/css'
styleSheet.innerText = styles
document.head.appendChild(styleSheet)
