import React, { useCallback, useMemo, useState, lazy, Suspense } from 'react'
import { Box, Flex, SimpleGrid, Spinner, Text, useToast } from '@chakra-ui/core'
import InfiniteScroll from 'react-infinite-scroller'
import { Loader } from 'components/CommonComponents/Loader'
import { useInsightsContext } from '../../InsightsStackProvider'
import keys from 'constants/keys'
import useLocaleText from 'components/useLocaleText'
import { SelectDropdown } from 'components/CommonComponents/Select/Control'
import { useSubtasksMediaOverview } from 'components/TasksV2/hooks/useSubtasksMediaOverview'
import { getSubtasksByType } from '../../utils'
import ExportToExcelButton from 'components/CommonComponents/ExportToExcelButton'
import { exportMediaToExcel } from './utils'
import { useSelector } from 'react-redux'
import { IFileDownload, RootState } from 'constants/interfaces'
import { ControlledCustomSwitch } from 'components/TasksV2/UI/ControlledCustomSwitch'
import { RadioCheckbox } from 'components/TasksV2/TasksOverview/Content/Main/SubTasks/Poll/RadioCheckbox'
import { MediaOverviewSkeleton } from './MediaOverviewSkeleton'
import { NoFocusButton } from 'components/CommonComponents/NoFocusButton'
import { downloadZipV2 } from 'utils'
import { Download } from 'lucide-react'
import colors from 'constants/colors'
import { getContextName } from 'hooks/useContextName'

const LazyResultListItem = lazy(() => import('./ResultListItem'))

const MediaResultsPreview: React.FC = () => {
  const { currentInsightTask, deepDiveInsights, recurrenceTs, insights: allInsights, tid } = useInsightsContext()
  const { insights, contexts } = deepDiveInsights

  const {
    config: { groups, retailUsersObject },
  } = useSelector((state: RootState) => state.config)
  const theme = useSelector((state: RootState) => state.general.theme)

  const [selectedSubtasksId, setSelectedSubtasksId] = useState<string[]>([])
  const [isChooseStoresMode, setIsChooseStoresMode] = useState(false)
  const [choosedStoresToExport, setChoosedStoresToExport] = useState<string[]>([])
  const [isDownLoading, setIsDownLoading] = useState(false)
  const toast = useToast()

  const t_no_media = useLocaleText('no_media')
  const task_overview_select_groups_button = useLocaleText('task_overview_select_groups_button')
  const task_overview_select_all_btn = useLocaleText('task_overview_select_all_btn')
  const clear = useLocaleText('clear')

  const { contextIds, subtasks } = getSubtasksByType({
    insights,
    allInsights,
    subtaskTypes: [keys.SUBTASK_TYPES.IMAGE_SUBTASK, keys.SUBTASK_TYPES.VIDEO_SUBTASKS],
    tid,
  })

  const subtaskIds = Array.from(new Set(subtasks.map((subtask) => subtask.st_id)))

  const { displayedContexts, mediaResults, loadMoreData, hasMore, loadingContexts } = useSubtasksMediaOverview(
    currentInsightTask,
    contextIds,
    recurrenceTs,
    subtaskIds
  )

  const contextsWithoutResults = [...Object.keys(contexts).filter((key) => !contextIds.includes(key))]

  const allContexts = useMemo(
    () => [...displayedContexts, ...contextsWithoutResults],
    [contextsWithoutResults, displayedContexts]
  )

  const handleSelectSubtask = (ids: string[]) => {
    setSelectedSubtasksId(ids)
  }
  const itemsDataArray = useMemo(() => {
    return subtasks.map((subtask) => {
      return { id: subtask.st_id, name: allInsights[tid].sub_tasks[subtask.st_id] }
    })
  }, [allInsights, subtasks, tid])

  const handleExportToExcel = useCallback(() => {
    let filteredMediaResults = mediaResults
    let filteredSubtasks = subtasks

    if (choosedStoresToExport.length > 0) {
      filteredMediaResults = filteredMediaResults.filter((result) => choosedStoresToExport.includes(result.context_id))
    }

    if (selectedSubtasksId.length > 0) {
      filteredMediaResults = filteredMediaResults.filter((result) => selectedSubtasksId.includes(result.st_id))

      filteredSubtasks = subtasks.filter((subtask) => selectedSubtasksId.includes(subtask.st_id))
    }

    exportMediaToExcel(filteredSubtasks, filteredMediaResults, currentInsightTask.title, groups, retailUsersObject!)
  }, [
    choosedStoresToExport,
    currentInsightTask.title,
    groups,
    mediaResults,
    retailUsersObject,
    selectedSubtasksId,
    subtasks,
  ])

  const handleDownloadZip = useCallback(async () => {
    const filteredMediaResults = choosedStoresToExport
      ? mediaResults.filter((result) => choosedStoresToExport.includes(result.context_id))
      : mediaResults
    setIsDownLoading(true)

    let generatedFiles: IFileDownload[] = []

    filteredMediaResults.forEach((result) => {
      const context_name = getContextName(result.context_id, groups, retailUsersObject!)
      const subtaskName = subtasks.find((subtask) => subtask.st_id === result.st_id)?.title

      result.images?.forEach((imageURL) => {
        const extension = imageURL.slice(imageURL.lastIndexOf('.'), imageURL.length)
        const newImage = {
          downloadUrl: imageURL,
          name: `${context_name}-${subtaskName}`,
          extension: extension,
        }
        generatedFiles.push(newImage)
      })

      result.videos?.forEach((videoURL) => {
        const extension = videoURL.slice(videoURL.lastIndexOf('.'), videoURL.length)
        const newVideo = {
          downloadUrl: videoURL,
          name: `${context_name}-${subtaskName}`,
          extension: extension,
        }
        generatedFiles.push(newVideo)
      })
    })

    try {
      await downloadZipV2(generatedFiles)
      toast({
        description: 'Download completed successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        description: 'Error occurred during download!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }

    setIsDownLoading(false)
  }, [choosedStoresToExport, groups, mediaResults, retailUsersObject, subtasks, toast])

  const handleChangeMode = useCallback(() => {
    setIsChooseStoresMode((prev) => !prev)
    setChoosedStoresToExport([])
  }, [])

  const handleChooseStores = useCallback(
    (storeId: string) => {
      if (choosedStoresToExport.includes(storeId)) {
        setChoosedStoresToExport((prev) => prev.filter((id) => id !== storeId))
      } else {
        setChoosedStoresToExport((prev) => [...prev, storeId])
      }
    },
    [choosedStoresToExport]
  )

  const handleSelectAll = useCallback(() => {
    if (choosedStoresToExport.length === displayedContexts.length) {
      setChoosedStoresToExport([])
    } else {
      setChoosedStoresToExport((prev) => Array.from(new Set([...prev, ...displayedContexts])))
    }
  }, [choosedStoresToExport.length, displayedContexts])

  const isLoading = Object.values(loadingContexts).some((value) => value) || isDownLoading

  return (
    <Flex
      flexDir="column"
      h="100%"
      alignItems="center"
      pt="10px"
      pointerEvents={isLoading ? 'none' : 'auto'}
      opacity={!isLoading ? 1 : 0.5}
    >
      <Flex alignItems="center" style={{ columnGap: '10px' }}>
        {displayedContexts.length > 0 && itemsDataArray.length > 1 && (
          <Box maxW="300px" minW="150px">
            <SelectDropdown
              itemsDataArray={itemsDataArray}
              isLoading={false}
              mode={keys.SELECT_MODES.SINGLE}
              isShowSearch={true}
              selectedIds={selectedSubtasksId}
              onSubmit={handleSelectSubtask}
              menuButtonLiteral="subtasks filter"
              controlButtonProps={{ borderRadius: '10px' }}
              btnH="40px"
            />
          </Box>
        )}
        <Box
          pointerEvents={
            !isChooseStoresMode ? 'auto' : isChooseStoresMode && choosedStoresToExport.length > 0 ? 'auto' : 'none'
          }
          opacity={!isChooseStoresMode ? 1 : isChooseStoresMode && choosedStoresToExport.length > 0 ? 1 : 0.5}
          cursor={
            !isChooseStoresMode
              ? 'pointer'
              : isChooseStoresMode && choosedStoresToExport.length > 0
              ? 'pointer'
              : 'not-allowed'
          }
        >
          <ExportToExcelButton actionWithoutArgs={handleExportToExcel} />
        </Box>

        <Box
          pointerEvents={!isDownLoading && isChooseStoresMode && choosedStoresToExport.length > 0 ? 'auto' : 'none'}
          opacity={isChooseStoresMode && choosedStoresToExport.length > 0 ? 1 : 0.5}
          cursor={isChooseStoresMode && choosedStoresToExport.length > 0 ? 'pointer' : 'not-allowed'}
        >
          <ExportToExcelButton
            icon={
              isDownLoading ? (
                <Spinner size="sm" color={colors.greyMain} />
              ) : (
                <Download size="18px" color={colors.greyMain} />
              )
            }
            actionWithoutArgs={handleDownloadZip}
            literal="download_zip"
          />
        </Box>
      </Flex>

      <Flex alignItems="center" style={{ columnGap: '10px' }} my="20px" transition="all 0.1s ease-in-out">
        <Flex alignItems="center" lineHeight="normal" style={{ columnGap: '5px' }}>
          <ControlledCustomSwitch isChecked={isChooseStoresMode} onChange={handleChangeMode} />
          <Text>{task_overview_select_groups_button}</Text>
        </Flex>
        {isChooseStoresMode && (
          <>
            <NoFocusButton h="unset" p="5px" fontSize="12px" onClick={handleSelectAll}>
              <Text>
                {choosedStoresToExport.length === displayedContexts.length ? clear : task_overview_select_all_btn}
              </Text>
            </NoFocusButton>
          </>
        )}
      </Flex>

      {displayedContexts.length === 0 ? (
        <Text fontSize="20px" textAlign="center">
          {t_no_media}
        </Text>
      ) : (
        <InfiniteScroll
          pageStart={0}
          style={{ height: '100%', width: '100%', overflowY: 'auto' }}
          loadMore={loadMoreData}
          hasMore={hasMore}
          loader={<Loader key="loader" />}
          useWindow={false}
        >
          <SimpleGrid minChildWidth="300px" spacing="20px" px="65px">
            {allContexts.map((contextId) => {
              const results =
                mediaResults?.filter((res) => {
                  const isContextMatch = res.context_id === contextId
                  const isSubtaskMatch = selectedSubtasksId.length === 0 || res.st_id === selectedSubtasksId[0]
                  return isContextMatch && isSubtaskMatch
                }) ?? []

              const isLoading = loadingContexts[contextId] ?? false

              return (
                <Flex
                  height="auto"
                  key={contextId}
                  maxW={{ base: '100%', md: displayedContexts.length === 1 ? '350px' : 'unset' }}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {isChooseStoresMode && (
                    <>
                      {results.length > 0 ? (
                        <RadioCheckbox
                          value={contextId}
                          isChecked={choosedStoresToExport.includes(contextId)}
                          onChange={() => handleChooseStores(contextId)}
                          fillColor={theme?.elementsColor}
                        />
                      ) : (
                        <Box>
                          <Box h="20px" w="20px" />
                        </Box>
                      )}
                    </>
                  )}

                  <Box
                    transition="all 0.1s ease-in-out"
                    transform={isChooseStoresMode ? 'scale(0.9)' : 'scale(1)'}
                    w={'100%'}
                    pointerEvents={isChooseStoresMode ? 'none' : 'auto'}
                    opacity={!isChooseStoresMode ? 1 : 0.5}
                  >
                    <Suspense fallback={<MediaOverviewSkeleton />}>
                      <LazyResultListItem
                        key={contextId}
                        contextId={contextId}
                        results={results}
                        isLoading={isLoading}
                        subtasks={subtasks}
                        selectedSubtasksId={selectedSubtasksId[0] ?? null}
                      />
                    </Suspense>
                  </Box>
                </Flex>
              )
            })}
          </SimpleGrid>
        </InfiniteScroll>
      )}
    </Flex>
  )
}

export default MediaResultsPreview
