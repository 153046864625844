import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import { AppDispatch } from 'config/redux'
import { getAllResults } from 'redux/actions/tasks_v2'
import { TaskDescription } from './TaskDescription'
import { Flex } from '@chakra-ui/core'
import { useTaskOverviewContext } from '../../TaskOverviewStackProvider'
import { CreatorRecieverInfo } from './CreatorRecieverInfo'
import Poll from './SubTasks/Poll'
import { MediaSubtasks } from './SubTasks/MediaSubtasks'

const Main: React.FC = () => {
  const { task } = useTaskOverviewContext()
  const {
    tid,
    created_by,
    users,
    groups: taskGroups,
    tags: taskTags,
    deadline,
    recurrence_deadline,
    is_recurring,
  } = task

  const dispatch: AppDispatch = useDispatch()
  const { subtasks } = useSelector((state: RootState) => state.tasks_v2)

  const sortedSubtasks = useMemo(
    () =>
      subtasks?.[tid]?.sort((a, b) => {
        return a.order_index - b.order_index
      }) ?? [],
    [subtasks, tid]
  )

  const handleGetAllResults = useCallback(async () => {
    const pollSubtasksIds = subtasks?.[tid]
      ?.filter((subtask) => subtask.type === keys.SUBTASK_TYPES.POLL_SUBTASK)
      .map((subtask) => subtask.st_id)
    if (!pollSubtasksIds?.length) return

    await dispatch(getAllResults({ task, subtasksIds: pollSubtasksIds }))
  }, [dispatch, subtasks, task, tid])

  const endDate = is_recurring ? recurrence_deadline : deadline

  useEffect(() => {
    handleGetAllResults()
  }, [handleGetAllResults])

  return (
    <>
      <CreatorRecieverInfo created_by={created_by} users={users} tags={taskTags} groups={taskGroups} ts={endDate} />

      <TaskDescription task={task} />

      <Flex flexDir="column" style={{ rowGap: '50px' }} w="100%" mt="50px">
        {sortedSubtasks.length > 0 &&
          sortedSubtasks.map((subtask) => {
            const type = subtask.type
            switch (type) {
              case keys.SUBTASK_TYPES.POLL_SUBTASK:
                return <Poll key={subtask.st_id} pollSubtask={subtask} />

              default:
                return <MediaSubtasks key={subtask.st_id} subtask={subtask} />
            }
          })}
      </Flex>
    </>
  )
}

export default Main
