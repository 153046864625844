import { RootState, Subtask } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'
import { Votes } from './Votes'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { WidgetIcon } from 'components/TasksV2/UI/WidgetIcon'
import { Box, Divider, Flex, Text } from '@chakra-ui/core'
import { SelectionPrompt } from 'components/TasksV2/UI/SelectionPrompt'
import { SubtaskTitile } from 'components/TasksV2/UI/SubtaskTitile'
import {
  parseRecurrenceTimestamp,
  TaskOverviewContentType,
  useTaskOverviewContext,
} from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'
import { StatusCircle } from 'components/TasksV2/UI/StatusCircle'
import colors from 'constants/colors'
import keys from 'constants/keys'
import useLocaleText from 'components/useLocaleText'

interface Props {
  pollSubtask: Subtask
}
const Poll = ({ pollSubtask }: Props) => {
  const { allResults } = useSelector((state: RootState) => state.tasks_v2)
  const { isLoading } = useSelector((state: RootState) => state.tasks_v2)
  const {
    openContent,
    resultContextId,
    task: { recurrence_ts },
  } = useTaskOverviewContext()
  const t_view_votes = useLocaleText('t_view_votes')

  if (!pollSubtask || !pollSubtask.options) {
    return null
  }

  const pollAllResults =
    allResults?.[pollSubtask.tid]?.filter((result) => {
      const recurrenceUnix = recurrence_ts ? parseRecurrenceTimestamp(recurrence_ts) : null
      const createAtCheck =
        result?.created_at_ts && recurrenceUnix !== null ? Number(result.created_at_ts) >= recurrenceUnix : true

      return result.st_id === pollSubtask.st_id && createAtCheck
    }) ?? []

  const isPollSubtaskDone = !!pollAllResults.filter(
    (result) => result.status === keys.TASK_STATUSES.DONE.value && result.context_id === resultContextId
  ).length

  const handleViewVotesClick = () => {
    if (isLoading) return
    openContent(TaskOverviewContentType.VOTES_OVERVIEW, { title: pollSubtask.title, selectedSubtask: pollSubtask })
  }

  return (
    <WidgetWrapper p="15px" pos="relative">
      {isPollSubtaskDone && <StatusCircle color={colors.greenPoll} />}

      <WidgetIcon subtaskType={pollSubtask.type} />

      <SubtaskTitile mt="30px" title={pollSubtask.title} />

      <Box my="10px">
        <SelectionPrompt isMuptiple={pollSubtask.is_multi_choice ?? false} />
      </Box>

      <Votes
        options={pollSubtask.options}
        isMultiple={pollSubtask.is_multi_choice ?? false}
        pollResults={pollAllResults}
        st_id={pollSubtask.st_id}
        tid={pollSubtask.tid}
      />

      <Box
        mt="30px"
        mx="-25px"
        mb="-25px"
        px="25px"
        pb="25px"
        cursor={isLoading ? 'not-allowed' : 'pointer'}
        color={colors.analyticGrey}
        onClick={handleViewVotesClick}
        borderBottomLeftRadius="15px"
        borderBottomRightRadius="15px"
      >
        <Flex flexDir="column" alignItems="center" w="100%">
          <Divider w="calc(100% + 30px)" mx="-25px" my={0} borderBottom="3px solid" />
          <Text fontSize="16px" mt="15px">
            {t_view_votes}
          </Text>
        </Flex>
      </Box>
    </WidgetWrapper>
  )
}

export default Poll
