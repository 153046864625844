// PollOptions.tsx
import React, { useEffect, useRef } from 'react'
import { Flex, Divider, Box, Text } from '@chakra-ui/core'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { TaskInput } from 'components/TasksV2/UI/TaskInput'
import { RenoveItemIcon } from 'components/TasksV2/UI/RenoveItemIcon'
import useLocaleText from 'components/useLocaleText'
import { Plus } from 'lucide-react'
import colors from 'constants/colors'

interface PollOptionsProps {
  optionsPath: string
}

const PollOptions: React.FC<PollOptionsProps> = ({ optionsPath }) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  const t_optionPlaceholder = useLocaleText('t_optionPlaceholder')
  const t_optionLabel = useLocaleText('t_optionLabel')

  const { setValue, control } = useFormContext()

  const {
    fields: optionFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: optionsPath,
    keyName: 'id',
  })

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Enter' && index === optionFields.length - 1) {
      event.preventDefault()
      append({ value: '' })
      setTimeout(() => inputRefs.current[index + 1]?.focus(), 0)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = event.target.value
    setValue(`${optionsPath}.${index}`, value)
  }

  useEffect(() => {
    if (optionFields.length < 2) {
      const defaultOptions = ['', '']
      const initializeOptions = async () => {
        for (const option of defaultOptions) {
          await append({ value: option })
        }
      }
      initializeOptions()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box my="20px">
      <Text fontWeight="medium" px="15px" fontSize="14px">
        {t_optionLabel}
      </Text>
      <Flex direction="column" border="2px solid #f0f0f0" borderRadius="10px" mt="5px" p="10px">
        {optionFields.map(({ id, value }, optIndex) => {
          const isLastOption = optIndex === optionFields.length - 1
          return (
            <React.Fragment key={id}>
              <Flex key={id} align="center" w="100%">
                <Controller
                  as={<TaskInput ref={(el: HTMLInputElement) => (inputRefs.current[optIndex] = el)} />}
                  name={`${optionsPath}.${optIndex}`}
                  control={control}
                  rules={{ required: 'Option is required' }}
                  defaultValue={value}
                  placeholder={`${t_optionPlaceholder} ${optIndex + 1}`}
                  border="none"
                  boxShadow="none"
                  borderRadius="none"
                  px="5px"
                  width="100%"
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(event, optIndex)}
                  onChange={([event]) => {
                    handleChange(event, optIndex)
                    return event.target.value
                  }}
                  inputElement={
                    <RenoveItemIcon
                      top="9px"
                      isDisabled={optionFields.length <= 2}
                      onClick={() => remove(optIndex)}
                      aria-label="Remove option"
                    />
                  }
                />
              </Flex>
              {!isLastOption && <Divider my={0} />}
            </React.Fragment>
          )
        })}
        <Flex style={{ cursor: 'pointer' }} onClick={() => append({ value: '' })} px="15px" justifyContent="center">
          <Plus size={16} />
        </Flex>
      </Flex>
    </Box>
  )
}
export default PollOptions
