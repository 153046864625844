import { Subtasks, TasksInitialState } from 'constants/interfaces'
import keys from 'constants/keys'
import { actionTypes } from 'redux/actions/tasks_v2'

const initialState: TasksInitialState = {
  isLoading: false,
  isInsightsLoading: false,
  tasks: [],
  subtasks: {},
  pollSubtasks: {},
  imageSubtasks: {},
  videoSubtasks: {},
  linkSubtasks: {},
  userResults: {},
  allResults: {},
  deepDiveInsights: {
    contexts: {},
    insights: {},
  },
  insights: {},
  taskFromInsights: null,
}

export const tasksV2Reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload }
    case actionTypes.SET_IS_INSIGHTS_LOADING:
      return { ...state, isInsightsLoading: action.payload }
    case actionTypes.SET_SUBTASKS:
      const subtasks = action.payload as Subtasks

      const pollSubtasks = Object.fromEntries(
        Object.entries(subtasks)
          .map(([key, valueArray]) => [
            key,
            valueArray.filter((value) => value.type === keys.SUBTASK_TYPES.POLL_SUBTASK),
          ])
          .filter(([key, valueArray]) => valueArray.length > 0)
      )

      const imageSubtasks = Object.fromEntries(
        Object.entries(subtasks)
          .map(([key, valueArray]) => [
            key,
            valueArray.filter((value) => value.type === keys.SUBTASK_TYPES.IMAGE_SUBTASK),
          ])
          .filter(([key, valueArray]) => valueArray.length > 0)
      )

      const videoSubtasks = Object.fromEntries(
        Object.entries(subtasks)
          .map(([key, valueArray]) => [
            key,
            valueArray.filter((value) => value.type === keys.SUBTASK_TYPES.VIDEO_SUBTASKS),
          ])
          .filter(([key, valueArray]) => valueArray.length > 0)
      )
      const linkSubtasks = Object.fromEntries(
        Object.entries(subtasks)
          .map(([key, valueArray]) => [
            key,
            valueArray.filter((value) => value.type === keys.SUBTASK_TYPES.LINK_SUBTASK),
          ])
          .filter(([key, valueArray]) => valueArray.length > 0)
      )

      return {
        ...state,
        subtasks: subtasks,
        pollSubtasks: pollSubtasks,
        imageSubtasks: imageSubtasks,
        videoSubtasks: videoSubtasks,
        linkSubtasks: linkSubtasks,
      }

    case actionTypes.SET_USER_RESULTS:
      return { ...state, userResults: action.payload }
    case actionTypes.SET_ALL_RESULTS:
      return { ...state, allResults: action.payload }
    case actionTypes.SET_TASKS:
      return { ...state, tasks: action.payload }
    case actionTypes.SET_TASKS_DEEP_DIVE_INSIGHTS:
      return { ...state, deepDiveInsights: action.payload }
    case actionTypes.SET_TASKS_INSIGHTS:
      return { ...state, insights: action.payload }
    case actionTypes.SET_FULL_TASK_FROM_INSIGHTS:
      return {
        ...state,
        taskFromInsights: action.payload,
      }
    default:
      return state
  }
}
