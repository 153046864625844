import React, { useState, useEffect, memo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import isSolid from 'is-solid'
import { Box, Flex, Spinner, useToast, Button } from '@chakra-ui/core'
import { getCurrentEpoch } from 'utils'
import useComments from 'hooks/useComments'
import LocaleText from 'components/LocaleText'
import CommentInput from './CommentInput'
import { IHandleCreateComment, IReplyToComment, RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import CommentItem from './CommentItem'
import { userIdSelector } from 'redux/selectors/config'
import { setCommentsCounter } from 'redux/actions/comments'
import useLocaleText from 'components/useLocaleText'
import { isEmpty } from 'lodash'

const initialReplyToComment = {
  userTag: undefined,
  commentID: undefined,
}

interface IProps {
  isDoublePost?: boolean
  message: string
  created_by: string
  created_at_ts: number
  cid: string
  postID: string
  authorID: string
  rasID: string
  taskId?: string
  subtaskType?: number
  images?: string[]
  videos?: string[]
  audios?: string[]
  files?: string[]
  postType?: number
  isPostCommnet?: boolean
  inputBgColor?: string
  keyForSave?: string
  recurrence_ts?: string
}

const Comment = ({
  isDoublePost,
  message,
  created_by,
  created_at_ts,
  cid,
  postID,
  authorID,
  rasID,
  taskId,
  subtaskType,
  images,
  videos,
  audios,
  files,
  postType,
  inputBgColor,
  keyForSave,
  recurrence_ts,
  isPostCommnet = true,
}: IProps) => {
  const params = useParams()
  const { retailUsersObject, myUID } = useSelector((state: RootState) => state.config.config)
  const currentUserID = useSelector(userIdSelector)
  const { isFetchReliesCounter } = useSelector((state: RootState) => state.comments)
  const t_unknown = useLocaleText('unknown')

  const toast = useToast()
  const {
    fetchComments,
    fetchCommentsCounter,
    sendComment,
    sendCommentLoading,
    fetchCommentsLoading,
    commentsCounter: repliesCounter,
    deleteComment: deleteReply,
    comments: replies,
  } = useComments(cid, true)
  const { commentsCounter, deleteComment } = useComments(postID, false, keyForSave)

  const dispatch = useDispatch()
  const [isShowReplies, setIsShowReplies] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showReplyCommentInput, setReplyCommentInput] = useState<boolean>(false)
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(2)

  const [replyToComment, setReplyToComment] = useState<IReplyToComment>(initialReplyToComment)

  const getComments = async () => {
    try {
      let timestampFromToGetComments = getCurrentEpoch()

      if (replies.length > 0) {
        timestampFromToGetComments = replies[0].created_at_ts
      }
      await fetchComments({
        offset,
        limit,
        created_ts: timestampFromToGetComments,
        parentCID: cid,
        isManuallyTriggered: true,
        id: keyForSave ? keyForSave : postID,
      })

      if (!isSolid(replies)) return

      setOffset(offset + limit)
      setLimit(5)
    } catch (error: any) {
      console.log(error.message)
    }
  }
  const deleteCommentHandler = async (cid: string) => {
    if (window.confirm('Are you sure ?')) {
      try {
        deleteComment({ id: postID, cid: cid, isReplyed: false })
        toast({
          title: 'Comment deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        console.log(replies)
        console.log(commentsCounter)

        dispatch(
          setCommentsCounter({
            count: commentsCounter - (repliesCounter || 0) - 1,
            key: postID,
            isReply: false,
          })
        )
      } catch (error) {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }

  const deleteReplyHandler = async (reply_cid: string) => {
    if (window.confirm('Are you sure ?')) {
      setIsLoading(true)
      try {
        deleteReply({ id: postID, cid: cid, reply_cid: reply_cid, isReplyed: true })
        toast({
          title: 'Comment deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        dispatch(
          setCommentsCounter({
            count: commentsCounter - 1,
            key: postID,
            isReply: false,
          })
        )
        dispatch(
          setCommentsCounter({
            count: repliesCounter - 1,
            key: cid,
            isReply: true,
          })
        )
      } catch (error) {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleCreateReply = async ({ comment, images, videos, audios, file }: IHandleCreateComment) => {
    const replyType = Object.prototype.hasOwnProperty.call(params, 'tid')
      ? keys.COMMENT_OBJECT_TYPE.COMMENT_SUBTASK_REPLY
      : keys.COMMENT_OBJECT_TYPE.COMMENT_REPLY
    try {
      const isResponseOk = await sendComment({
        id: postID,
        comment,
        rasID,
        objectType: keyForSave ? 23 : replyType,
        parentCID: cid,
        replyCID: replyToComment.commentID,
        createdByUserId: authorID,
        taskId,
        subtaskType,
        images,
        videos,
        audios,
        file,
        isPostCommnet,
        ...(postType === keys.POST_TYPE.GROUP_POST ? { is_update_post: true } : {}),
        ...(recurrence_ts ? { recurrence_ts } : {}),
      })
      if (isResponseOk) {
        dispatch(setCommentsCounter({ count: commentsCounter + 1, key: postID }))

        setReplyToComment(initialReplyToComment)
        setIsShowReplies(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleReplyClose = () => {
    setReplyCommentInput(false)
  }

  const handleRepliesShow = () => {
    getComments()
    setIsShowReplies(true)
  }

  const handleReplyInput = (replyTo: string = '', commentID?: string) => {
    setReplyCommentInput(!showReplyCommentInput)

    let replyToUserName: string | undefined

    if (isSolid(replyTo)) {
      replyToUserName = ` ${retailUsersObject?.[replyTo]?.first_name} ${
        retailUsersObject?.[replyTo]?.last_name ? retailUsersObject?.[replyTo]?.last_name : ''
      } `
    }

    setReplyToComment((prev) => ({
      ...prev,
      commentID,
      userTag: replyToUserName,
    }))
  }
  const renderUserName = (createdBy: string): string => {
    if (retailUsersObject && retailUsersObject[createdBy]) {
      const currentUser = retailUsersObject[createdBy]
      return `${currentUser['first_name']} ${currentUser['last_name'] ? currentUser['last_name'] : ''}`
    }
    return t_unknown
  }
  useEffect(() => {
    if (isDoublePost || postType === keys.POST_TYPE.GROUP_POST || !isEmpty(commentsCounter)) return
    else if (isFetchReliesCounter) fetchCommentsCounter(cid, postID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (replies.length === repliesCounter && postType !== keys.POST_TYPE.GROUP_POST) {
      setIsShowReplies(true)
    }
  }, [replies.length, repliesCounter, isShowReplies, postType])
  return (
    <>
      <Flex flexDirection="column" className="comment-wrapper" mb="10px">
        <CommentItem
          deleteCommentHandler={deleteCommentHandler}
          handleReplyInput={handleReplyInput}
          isCurrentUser={created_by === currentUserID || created_by === myUID}
          isLoading={isLoading}
          userName={renderUserName(created_by)}
          message={message}
          images={images}
          videos={videos}
          createdAt={created_at_ts}
          createdBy={created_by}
          cid={cid}
          audios={audios}
          files={files}
          isReply={false}
          postType={postType}
        />

        {postType !== keys.POST_TYPE.GROUP_POST && (repliesCounter !== 0 || isSolid(replies)) && (
          <Box
            className="child-replies"
            px={{
              lg: '2.75rem',
              base: '2.75rem',
            }}
          >
            {repliesCounter > replies.length && (
              <Flex justifyContent="flex-start">
                <Button
                  mt="4px"
                  textAlign="center"
                  color="#D5158C"
                  cursor="pointer"
                  variant="link"
                  fontSize="14px"
                  onClick={() => handleRepliesShow()}
                >
                  <Box width="5px" height="5px" backgroundColor="#D5158C" borderRadius="50%" mx="5px" />
                  <LocaleText text="view_more_reply" />
                  {fetchCommentsLoading ? (
                    <Spinner size="xs" color="#D5158C" mx="10px" />
                  ) : (
                    '(' + (repliesCounter - replies.length) + ')'
                  )}
                </Button>
              </Flex>
            )}

            {isShowReplies &&
              replies.map((reply) => {
                return (
                  <CommentItem
                    key={reply.cid}
                    deleteReplyHandler={deleteReplyHandler}
                    handleReplyInput={handleReplyInput}
                    isCurrentUser={reply.created_by === currentUserID}
                    userName={renderUserName(reply.created_by)}
                    message={reply.message}
                    images={reply.images}
                    videos={reply.videos}
                    audios={reply.audios}
                    createdAt={reply.created_at_ts}
                    createdBy={reply.created_by}
                    cid={reply.cid}
                    files={reply.files}
                    replyTo={reply.reply_to}
                    replyCid={cid}
                    isReply={true}
                  />
                )
              })}
          </Box>
        )}

        {showReplyCommentInput && (
          <Box px="0.6rem" mt="20px">
            <CommentInput
              isFocused={showReplyCommentInput}
              postID={postID}
              isReply={true}
              handleCreateComment={handleCreateReply}
              sendCommentLoading={sendCommentLoading}
              userTag={replyToComment.userTag}
              replyCID={replyToComment.commentID}
              closeCallback={handleReplyClose}
              inputBgColor={inputBgColor}
            />
          </Box>
        )}
      </Flex>
    </>
  )
}

export default memo(Comment)
