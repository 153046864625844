import React, { memo } from 'react'
import { Flex, Link } from '@chakra-ui/core'
import { RootState, Subtask } from 'constants/interfaces'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { WidgetIcon } from 'components/TasksV2/UI/WidgetIcon'
import { StatusCircle } from 'components/TasksV2/UI/StatusCircle'
import { SubtaskTitile } from 'components/TasksV2/UI/SubtaskTitile'
import { getFileExtension, openInCloudViewer } from 'utils'
import { updateResults } from 'redux/actions/tasks_v2'
import keys from 'constants/keys'
import { AppDispatch } from 'config/redux'
import { useDispatch, useSelector } from 'react-redux'
import { getFormattedLink, isExternalLink, isSubtaskAlreadyProcessed } from 'components/TasksV2/TasksOverview/utils'
import colors from 'constants/colors'
import { useTaskOverviewContext } from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'
import { useResults } from 'components/TasksV2/hooks/useResults'
// import { LinkPreview } from 'components/LinkPreview'

interface IProps {
  subtask: Subtask
}
export const LinkSubtask: React.FC<IProps> = memo(({ subtask }) => {
  const { type, title, external_link, st_id, tid } = subtask
  const { resultContextId, isReadMode } = useTaskOverviewContext()
  const subTaskResult = useResults({ subtask, resultContextId })

  const dispatch: AppDispatch = useDispatch()
  const {
    config: { tags, first_name, last_name, default_group, groups, rid },
    activeGroupID,
  } = useSelector((state: RootState) => state.config)
  const { userResults, tasks } = useSelector((state: RootState) => state.tasks_v2)

  const uid = useSelector((state: RootState) => state.auth.uid)

  const handleClickOnLink = () => {
    const { external_link } = subtask
    const isExternalLinkHasSpecialPhrase = !!external_link && isExternalLink(external_link)

    const formattedLink = getFormattedLink({
      link: external_link!,
      tasks,
      tid,
      st_id,
      contextId: resultContextId,
      uid: uid!,
      userResults,
      tags,
      first_name,
      last_name,
      default_group,
      groups,
      activeGroupID: activeGroupID!,
      rid,
    })

    const fileExtension = getFileExtension(formattedLink)
    if (keys.extensionsForCloudViewer.includes(fileExtension)) {
      window.open(openInCloudViewer(formattedLink), '_blank')
    } else {
      window.open(formattedLink, '_blank')
    }

    if (isReadMode) {
      return
    }

    if (!isSubtaskAlreadyProcessed({ tid, st_id, contextId: resultContextId, userResults })) {
      dispatch(
        updateResults({
          tid,
          st_id,
          type: keys.SUBTASK_TYPES.LINK_SUBTASK,
          isExternalLink: isExternalLinkHasSpecialPhrase,
          resultContextId,
          status: isExternalLink(external_link!) ? keys.TASK_STATUSES.INPROGRESS.value : keys.TASK_STATUSES.DONE.value,
        })
      )
    }
  }

  return (
    <WidgetWrapper cursor={isReadMode ? 'default' : 'pointer'} onClick={handleClickOnLink} p="15px">
      <WidgetIcon subtaskType={type} />

      {subTaskResult && <StatusCircle color={colors.greenPoll} />}
      <Flex mt="35px" mb="25px" flexDir="column" justifyContent={'center'} style={{ rowGap: '5px' }}>
        <SubtaskTitile title={title} mt="0px" mb="0px" />
        <Link
          color="blue.500"
          fontSize="14px"
          _focus={{ boxShadow: 'none', outline: 'none', textDecoration: 'none' }}
          textAlign="center"
        >
          {external_link}
        </Link>
      </Flex>

      {/* {external_link && <LinkPreview width="100%" url={external_link} />} */}
    </WidgetWrapper>
  )
})
