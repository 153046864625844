import { Avatar, Collapse, Divider, Flex, Icon, Stack, Text } from '@chakra-ui/core'
import { getAvatarSrc } from 'components/Tasks/utils'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { useTaskOverviewContext } from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import 'dayjs/locale/en'

dayjs.extend(localeData)

interface IProps {
  participants: {
    id: string
    date?: string
    isClickable?: boolean
    insights?: {
      items: any[]
      typeCounts: {
        [type: number]: number
      }
    }
  }[]
}

export const VotedParticipants = ({ participants }: IProps) => {
  const { contentStack } = useTaskOverviewContext()
  const currentContent = contentStack[contentStack.length - 1]

  const isRtl = useSelector(isRtlSelector)
  const { locale } = useSelector((state: RootState) => state.general)
  const { groups } = useSelector((state: RootState) => state.config.config)
  const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
  const [showAll, setShowAll] = useState(false)

  const visibleParticipants = participants.slice(0, 7)
  const collapedParticipants = participants.slice(7)

  const handleToggle = () => setShowAll(!showAll)

  return (
    <Stack direction="column" width="100%">
      {visibleParticipants.map(({ id, date }, index) => {
        const avatar = getAvatarSrc({ id: id, groups: groups, retailUsersObject: retailUsersObject! })
        const participantName = retailUsersObject?.[id]
          ? `${retailUsersObject?.[id].first_name} ${retailUsersObject?.[id].last_name ?? ''}`
          : groups[id]?.name ?? ''

        return (
          <React.Fragment key={`${id}-${index}`}>
            <Flex direction="row" alignItems="center" justifyContent="space-between">
              <Flex direction="row" alignItems="center" fontSize="16px" style={{ columnGap: '10px' }}>
                <Avatar src={avatar} />
                <Flex direction="column" alignItems="flex-start" justifyContent="center">
                  <Text fontWeight={600}>{participantName}</Text>
                  {date && <Text>{dayjs(date).locale(locale).format('DD MMMM YYYY HH:mm')}</Text>}
                </Flex>
              </Flex>
            </Flex>
            <Divider />
          </React.Fragment>
        )
      })}
      {participants.length > 7 && (
        <>
          <Collapse isOpen={showAll}>
            {collapedParticipants.map(({ id, date, isClickable }, index) => {
              const avatar = getAvatarSrc({ id: id, groups: groups, retailUsersObject: retailUsersObject! })
              const participantName = retailUsersObject?.[id]
                ? `${retailUsersObject?.[id].first_name} ${retailUsersObject?.[id].last_name ?? ''}`
                : groups?.[id]?.name ?? ''
              return (
                <React.Fragment key={`${id}-${index}`}>
                  <Flex direction="row" alignItems="center" justifyContent="space-between">
                    <Flex direction="row" alignItems="center" style={{ columnGap: '10px' }}>
                      <Avatar src={avatar} />
                      <Flex direction="column" alignItems="flex-start" justifyContent="center">
                        <Text fontWeight="bold">{participantName}</Text>
                        <Text>{dayjs(date).locale(locale).format('DD MMMM YYYY HH:mm')}</Text>
                      </Flex>
                    </Flex>
                    {isClickable && !currentContent.props.isReadMode && (
                      <Icon name={isRtl ? 'chevron-left' : 'chevron-right'} size="25px" cursor="pointer" />
                    )}
                  </Flex>
                  {index !== collapedParticipants.length - 1 && <Divider />}
                </React.Fragment>
              )
            })}
          </Collapse>
          <Flex
            cursor="pointer"
            justifyContent="space-between"
            alignItems="center"
            onClick={handleToggle}
            width="100%"
            pt="3px"
          >
            <Text color={colors.greyMain} fontWeight="500">
              {showAll ? 'Show Less' : 'Show More'}
            </Text>
            <Icon name="chevron-right" />
          </Flex>
        </>
      )}
    </Stack>
  )
}
