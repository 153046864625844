import { SubtaskDeepDiveItem, TasksInsights, TransformedDataWithCounts } from 'redux/actions/tasks_v2'

type GetSubtasksByTypeArgs = {
  insights: TransformedDataWithCounts
  allInsights: TasksInsights
  tid: string
  subtaskTypes: number[]
}
export const getSubtasksByType = ({ insights, allInsights, tid, subtaskTypes }: GetSubtasksByTypeArgs) => {
  return Object.keys(insights).reduce<{
    contextIds: string[]
    subtasks: SubtaskDeepDiveItem[]
  }>(
    (acc, key) => {
      const insight = insights[key]

      const hasMatchingSubtask = insight.items.some((item) => subtaskTypes.includes(item.type))

      if (hasMatchingSubtask) {
        acc.contextIds.push(key)

        const matchingSubtasks = insight.items.filter((item) => subtaskTypes.includes(item.type))

        matchingSubtasks.forEach((subtask) => {
          if (!acc.subtasks.some((existing) => existing.st_id === subtask.st_id)) {
            const subtaskWithTitle = { ...subtask, title: allInsights[tid].sub_tasks[subtask.st_id] }
            acc.subtasks.push(subtaskWithTitle)
          }
        })
      }

      return acc
    },
    { contextIds: [], subtasks: [] }
  )
}
