import React, { useMemo } from 'react'
import { RootState, Task } from 'constants/interfaces'
import TaskModal from 'components/TasksV2/UI/TaskModal'
import { BackButton } from 'components/TasksV2/UI/BackButton'
import { Box, Button, Flex } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import Main from '../Content/Main'
import VotesOverview from '../Content/VotesOverview'
import {
  TaskOverviewStackProvider,
  TaskOverviewContentType,
  ITaskOverviewContentStack,
  useTaskOverviewContext,
} from '../TaskOverviewStackProvider'
import ModalMediaSection from '../Content/MediaSubtasksCompleting'

interface IProps {
  task: Task
  onClose: () => void
  content?: ITaskOverviewContentStack
  isComeFromDashboard?: boolean
}

const TaskOverviewModalContent: React.FC<IProps> = ({ task, onClose, isComeFromDashboard = false }) => {
  const { contentStack, closeContent, isTaskCompleted, isSubtaskCompleted, resultContextId, isReadMode } =
    useTaskOverviewContext()
  const theme = useSelector((state: RootState) => state.general.theme)
  const { isLoading } = useSelector((state: RootState) => state.tasks_v2)

  const currentContent = contentStack[contentStack.length - 1]

  const headerTitle = useMemo(() => {
    if (!currentContent) return null
    const { contentType, props } = currentContent
    switch (contentType) {
      case TaskOverviewContentType.MAIN:
        return task.title
      case TaskOverviewContentType.VOTES_OVERVIEW:
        return props?.title
      case TaskOverviewContentType.MEDIA_SUBTASK_COMPLETING:
        return props.selectedSubtask.title
      default:
        return null
    }
  }, [currentContent, task.title])

  const isDoneBtnDisabled = useMemo(() => {
    if (currentContent?.contentType === TaskOverviewContentType.MAIN) {
      return !isTaskCompleted
    }
    if (currentContent?.contentType === TaskOverviewContentType.MEDIA_SUBTASK_COMPLETING) {
      return !isSubtaskCompleted
    }
    return false
  }, [currentContent, isSubtaskCompleted, isTaskCompleted])

  return (
    <TaskModal
      isOpen={true}
      onClose={onClose}
      modalFooterProps={{ alignItems: 'center', justifyContent: 'center', bg: isComeFromDashboard ? '#f4f4f4' : '' }}
      header={
        <Flex justifyContent="space-between" h="60px">
          <BackButton title={headerTitle} event={closeContent} />
        </Flex>
      }
      body={
        <>
          {contentStack.map((content, index) => {
            const { contentType, props } = content
            const isTopContent = index === contentStack.length - 1

            switch (contentType) {
              case TaskOverviewContentType.MAIN:
                return (
                  <Box display={isTopContent ? 'block' : 'none'} key={`${index}-${content}`}>
                    <Main key={index} />
                  </Box>
                )
              case TaskOverviewContentType.VOTES_OVERVIEW:
                return (
                  props?.selectedSubtask && (
                    <Box display={isTopContent ? 'block' : 'none'} key={`${index}-${content}`}>
                      <VotesOverview pollSubtask={props.selectedSubtask} />
                    </Box>
                  )
                )
              case TaskOverviewContentType.MEDIA_SUBTASK_COMPLETING:
                return (
                  props?.selectedSubtask && (
                    <Flex
                      display={isTopContent ? 'flex' : 'none'}
                      key={`${index}-${content}`}
                      flexDir="column"
                      overflow={'hidden'}
                      h="100%"
                    >
                      <ModalMediaSection
                        subtask={props.selectedSubtask}
                        resultContextId={resultContextId}
                        isReadMode={isReadMode}
                        recurrence_ts={task.recurrence_ts}
                        retail_id_context_id={task.retail_id_context_id}
                      />
                    </Flex>
                  )
                )
              default:
                return null
            }
          })}
        </>
      }
      footer={
        !isComeFromDashboard ? (
          <Button
            onClick={closeContent}
            isDisabled={isLoading || isDoneBtnDisabled}
            borderRadius="20px"
            m="10px"
            width="70%"
            minW="300px"
            bg={theme?.elementsColor}
            color="white"
            border="none"
            h="40px"
            _focus={{ boxShadow: 'none' }}
            _hover={{ opacity: 0.6 }}
          >
            Done
          </Button>
        ) : (
          <></>
        )
      }
    />
  )
}

export const TaskOverviewModal: React.FC<IProps> = (props) => {
  return (
    <TaskOverviewStackProvider task={props.task} content={props.content} onClose={props.onClose}>
      <TaskOverviewModalContent {...props} />
    </TaskOverviewStackProvider>
  )
}
