import useLocaleText from 'components/useLocaleText'
import keys from 'constants/keys'
import { Subtask } from '../../FormStepContext'

interface IProps {
  errors: any
  index: number
  field: Subtask
}

export const useWidgetErrorMessage = ({ errors, index, field }: IProps): string | null => {
  const error = errors?.sub_tasks?.[index] || null
  const errorType = field.type || null

  const errorMessages = {
    [keys.SUBTASK_TYPES.POLL_SUBTASK]: useLocaleText('t_poll_st_error'),
    [keys.SUBTASK_TYPES.IMAGE_SUBTASK]: useLocaleText('t_img_st_error'),
    [keys.SUBTASK_TYPES.VIDEO_SUBTASKS]: useLocaleText('t_video_st_error'),
    [keys.SUBTASK_TYPES.LINK_SUBTASK]: useLocaleText('t_link_st_error'),
    [keys.SUBTASK_TYPES.UPLOAD_FILE_SUBTASK]: useLocaleText('t_file_st_error'),
  }

  return errorType && error ? errorMessages[errorType] : null
}
