import React from 'react'
import BaseModal from '../BaseModal'
import { Box, Button, Divider, Flex, IconButton, Text, useDisclosure } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { BsArrowLeftShort } from 'react-icons/bs'
import colors from 'constants/colors'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { AudienceOptionsList } from './AudienceOptionsList'
import { IAudiencePublicationsModal } from './types'

const AudiencePublicationsModal = ({
  isOpen,
  onClose,
  setShowOptionsList,
  renderViewOptionModal,
  selectedUsersUids,
  groupsToPayload,
  title = 'post_audience_main',
  isShowWarning = false,
  groups,
  users,
  selectedTagsIds,
  selectedOptionType,
}: IAudiencePublicationsModal) => {
  const { theme, locale } = useSelector((state: RootState) => state?.general)
  const isHebrew = locale === 'he'
  const isRtl = isHebrew ? 'rtl' : 'ltr'
  const { isOpen: isOpenWarning, onOpen: onOpenWarning, onClose: onCloseWarning } = useDisclosure()

  const handleSubmit = () => {
    if (isShowWarning) {
      onOpenWarning()
    } else {
      setShowOptionsList?.(false)
      onClose?.()
    }
  }
  return (
    <>
      {isOpenWarning ? (
        <BaseModal
          isOpen={isOpenWarning}
          onClose={onCloseWarning}
          hideCloseButton={true}
          bodyContent={
            <Box p="6" minH="100px">
              <Text fontSize="18px" textAlign="center">
                <LocaleText text="create_task_audience_duplication_confirmation" />
              </Text>
            </Box>
          }
          footerContent={
            <Flex width="100%" justifyContent="space-around">
              <Button
                onClick={() => {
                  setShowOptionsList?.(false)
                  onCloseWarning()
                }}
                color={'white'}
                background={theme?.elementsColor}
                mr={locale !== 'he' ? '3' : '0'}
                ml={locale !== 'he' ? '0' : '3'}
              >
                <LocaleText text="yes" />
              </Button>
              <Button onClick={() => onCloseWarning()} variant="ghost" color={theme?.elementsColor}>
                <LocaleText text="cancel" />
              </Button>
            </Flex>
          }
        />
      ) : (
        <BaseModal
          isOpen={isOpen}
          onClose={onClose}
          hideCloseButton={true}
          headerContent={
            <>
              <Flex alignItems="center" justifyContent="space-between" dir={isRtl}>
                <Box onClick={() => setShowOptionsList?.(false)} mx="4" position="absolute">
                  <IconButton
                    aria-label="close groups"
                    icon={BsArrowLeftShort}
                    borderRadius="50%"
                    style={{ color: colors.greyMain }}
                    fontSize="32px"
                    transform={`rotate(${+isHebrew * 180}deg)`}
                  />
                </Box>

                <Text m="0" textAlign="center" fontWeight="bold" flexGrow={1}>
                  <LocaleText text={title} />
                </Text>
              </Flex>
              <Divider pt="5px" />
            </>
          }
          bodyContent={
            <AudienceOptionsList
              renderViewOptionModal={renderViewOptionModal}
              groupsToPayload={groupsToPayload}
              selectedUsersUids={selectedUsersUids}
              selectedTagsIds={selectedTagsIds}
              selectedOptionType={selectedOptionType}
              groups={groups}
              users={users}
            />
          }
          footerContent={
            <>
              <Flex justifyContent="flex-end" background="white" px="6" py={5}>
                <Button
                  borderRadius="20px"
                  onClick={handleSubmit}
                  color="white"
                  textTransform="uppercase"
                  background={theme?.elementsColor}
                  _hover={{ backgroundColor: 'none' }}
                >
                  <LocaleText text="post_audience_done" />
                </Button>
              </Flex>
            </>
          }
        />
      )}
    </>
  )
}

export default AudiencePublicationsModal
