// TaskCreationModal.tsx
import React from 'react'
import { Content } from './Content'
import { Footer } from './Footer'
import TaskModal from '../../UI/TaskModal'
import { Header } from './Header'
import { FormStepProvider, TaskFormData } from '../FormStepContext'

interface IProps {
  onClose: () => void
  isOpen: boolean
  initialData?: Partial<TaskFormData>
  isEdit?: boolean
  tid?: string
}

const TaskCreationModal: React.FC<IProps> = ({ onClose, isOpen, initialData, tid, isEdit = false }) => {
  return (
    <FormStepProvider onClose={onClose} initialData={initialData} isEdit={isEdit} tid={tid}>
      <TaskModal
        modalFooterProps={{
          background: '#f4f4f4',
          alignItems: 'center',
          justifyContent: 'center',
          p: '0.5rem 1.5rem 1.5rem',
        }}
        isOpen={isOpen}
        onClose={onClose}
        header={<Header onClose={onClose} />}
        body={<Content />}
        footer={<Footer />}
      />
    </FormStepProvider>
  )
}

export default TaskCreationModal
