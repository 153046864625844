import React from 'react'
import { Box, Flex } from '@chakra-ui/core'
import { css } from '@emotion/core'
import { CameraIcon, LinkIcon, PollIcon, VideoIcon } from 'components/CommonComponents/JSXIcons'
import keys from 'constants/keys'
import { PollSubtask, StepType, Subtask, useFormStepContext } from '../FormStepContext'

export const Footer = () => {
  const { subtaskActions, stepStack, isEdit } = useFormStepContext()

  const iconHoverEffect = css`
    display: flex;
    align-items: center;
    column-gap: 30px;

    & > * {
      transition: transform 0.3s ease, margin 0.3s ease;
    }

    & > *:hover {
      transform: scale(1.2);
    }
  `

  const handleAddSubtask = (type: string) => {
    switch (type) {
      case 'Poll':
        subtaskActions.append?.({
          title: '',
          is_multi_choice: false,
          type: keys.SUBTASK_TYPES.POLL_SUBTASK,
          options: ['', ''],
        } as PollSubtask)
        break
      case 'Image':
        subtaskActions.append?.({
          title: '',
          type: keys.SUBTASK_TYPES.IMAGE_SUBTASK,
        } as Subtask)
        break
      case 'Video':
        subtaskActions.append?.({
          title: '',
          type: keys.SUBTASK_TYPES.VIDEO_SUBTASKS,
        } as Subtask)
        break
      case 'Link':
        subtaskActions.append?.({
          title: '',
          type: keys.SUBTASK_TYPES.LINK_SUBTASK,
          external_link: '',
        } as Subtask)
        break
      default:
        break
    }
  }

  return (
    <>
      {stepStack[stepStack.length - 1] === StepType.SUBTASK_TASK_CREATION && !isEdit ? (
        <Flex css={iconHoverEffect} bg="transparent">
          <PollIcon cursor="pointer" onClick={() => handleAddSubtask('Poll')} />
          <LinkIcon cursor="pointer" onClick={() => handleAddSubtask('Link')} />
          <CameraIcon cursor="pointer" onClick={() => handleAddSubtask('Image')} />
          <VideoIcon cursor="pointer" onClick={() => handleAddSubtask('Video')} />
        </Flex>
      ) : (
        <Box padding="20px" />
      )}
    </>
  )
}
