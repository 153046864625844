import { Flex, Text } from '@chakra-ui/core'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
interface Props {
  title: string
  event: VoidFunction
}
export const BackButton: React.FC<Props> = ({ title, event }) => {
  const isRtl = useSelector(isRtlSelector)
  return (
    <Flex alignItems="center" cursor="pointer" onClick={event} style={{ columnGap: '10px' }}>
      {isRtl ? <ChevronRight size={25} /> : <ChevronLeft size={25} />}
      <Text fontSize="20px" fontWeight="bold">
        {title}
      </Text>
    </Flex>
  )
}
