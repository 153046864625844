import { Modal, ModalBody, ModalContent, ModalHeader, ModalFooter, ModalOverlay, Flex } from '@chakra-ui/core'
import React from 'react'

interface IProps {
  isOpen: boolean
  onClose: () => void
  header: JSX.Element
  body: JSX.Element
  footer: JSX.Element
  modalProps?: Partial<React.ComponentProps<typeof Modal>>
  modalOverlayProps?: Partial<React.ComponentProps<typeof ModalOverlay>>
  modalContentProps?: Partial<React.ComponentProps<typeof ModalContent>>
  modalHeaderProps?: Partial<React.ComponentProps<typeof ModalHeader>>
  modalBodyProps?: Partial<React.ComponentProps<typeof ModalBody>>
  modalFooterProps?: Partial<React.ComponentProps<typeof ModalFooter>>
}

const TaskModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  header,
  body,
  footer,
  modalProps,
  modalOverlayProps,
  modalContentProps,
  modalHeaderProps,
  modalBodyProps,
  modalFooterProps,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
      blockScrollOnMount={true}
      {...modalProps}
    >
      <ModalOverlay {...modalOverlayProps} />
      <ModalContent w="70vw" maxW="800px" minW="400px" borderRadius="15px" my={0} {...modalContentProps}>
        <ModalHeader px="20px" py="10px" zIndex={5} boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)" {...modalHeaderProps}>
          {header}
        </ModalHeader>
        <ModalBody px="none" py="none" {...modalBodyProps}>
          <Flex
            flexDir="column"
            height="calc(90vh - 132px)"
            py="15px"
            px="25px"
            overflowY="auto"
            bg="#f4f4f4"
            justifyContent="space-between"
            data-scroll-container
          >
            {body}
          </Flex>
        </ModalBody>
        <ModalFooter
          overflow="hidden"
          borderBottomLeftRadius="15px"
          borderBottomRightRadius="15px"
          {...modalFooterProps}
        >
          {footer}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TaskModal
