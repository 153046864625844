import { Button, ButtonProps } from '@chakra-ui/core'
import React from 'react'

interface Props extends ButtonProps {}
export const NoFocusButton: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Button
      bg="white"
      borderRadius="10px"
      border="none"
      _focus={{ outline: 'none', boxShadow: 'none' }}
      _active={{ bg: 'none', transform: 'none' }}
      {...props}
    >
      {children}
    </Button>
  )
}
