import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IConfigRetailUser, IConfigStateGroups, RootState } from 'constants/interfaces'

export const getContextName = (
  contextId: string,
  groups: IConfigStateGroups,
  retailUsersObject: { [key: string]: IConfigRetailUser }
) => {
  return (
    groups?.[contextId]?.name ??
    `${retailUsersObject?.[contextId]?.first_name} ${retailUsersObject?.[contextId]?.last_name ?? ''}`
  )
}
export const useContextName = (contextId: string) => {
  const {
    config: { groups, retailUsersObject },
  } = useSelector((state: RootState) => state.config)

  const resultName = useMemo(
    () =>
      groups?.[contextId]?.name ??
      `${retailUsersObject?.[contextId]?.first_name} ${retailUsersObject?.[contextId]?.last_name ?? ''}`,
    [contextId, groups, retailUsersObject]
  )

  return resultName
}
