import { httpAuth } from 'config/apiClient'
import endpoints from 'constants/endpoints'
import { IComment, RootState } from 'constants/interfaces'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'

export const actionTypes = {
  SET_COMMENTS: '[FEED] SET_COMMENTS',
  SET_COMMENTS_COUNTER: '[FEED] SET_COMMENTS_COUNTER',
  SET_IS_FETCH_REPLIES_COUNTER: '[FEED] SET_IS_FETCH_REPLIES_COUNTER',
  SET_VIEWED_COMMENTS_IDS: '[FEED] SET_VIEWED_COMMENTS_IDS',
  SET_COMMENT_ACTIVITY: '[FEED] SET_COMMENT_ACTIVITY',
  UPDATE_COMMENTS_LIKES: '[FEED] UPDATE_COMMENTS_LIKES',
  SET_IS_FETCHING: '[FEED] SET_IS_FETCHING',
  UPDATE_COMMENTS_VIEWS: '[FEED] UPDATE_COMMENTS_VIEWS',
}
export interface ICommentActivity {
  likes: {
    [key: string]: boolean
  }
  views: {
    [key: string]: boolean
  }
}

export const setIsFetching = (isFetching: boolean) => ({
  type: actionTypes.SET_IS_FETCHING,
  payload: isFetching,
})

export const setCommentActivity = ({ oid, data }: { oid: string; data: ICommentActivity }) => ({
  type: actionTypes.SET_COMMENT_ACTIVITY,
  payload: { oid, data },
})

export const updateCommentsLikes = ({ oid, uids }: { oid: string; uids: string[] }) => ({
  type: actionTypes.UPDATE_COMMENTS_LIKES,
  payload: { oid, uids },
})
export const updateCommentsViews = ({ oid, uids }: { oid: string; uids: string[] }) => ({
  type: actionTypes.UPDATE_COMMENTS_VIEWS,
  payload: { oid, uids },
})

export const setComments = ({
  items,
  hasMore,
  key,
  isReply = false,
}: {
  items: IComment[]
  hasMore?: boolean
  key: string
  isReply?: boolean
}) => ({
  type: actionTypes.SET_COMMENTS,
  payload: { items, hasMore, key, isReply },
})
export const setCommentsCounter = ({ count, key, isReply }: { count: number; key: string; isReply?: boolean }) => ({
  type: actionTypes.SET_COMMENTS_COUNTER,
  payload: { count, key, isReply },
})

export const setIsFetchRepliesCounter = (isFetch: boolean) => ({
  type: actionTypes.SET_IS_FETCH_REPLIES_COUNTER,
  payload: isFetch,
})
export const setViewedCommentsIds = ({ key, ids }: { key: string; ids: string[] }) => ({
  type: actionTypes.SET_VIEWED_COMMENTS_IDS,
  payload: { key, ids },
})
interface IUpdateViews {
  postID: string
  viewedIds: string[]
}
export const updateViews =
  ({ postID, viewedIds }: IUpdateViews): ThunkAction<any, RootState, unknown, AnyAction> =>
  async (_, getConfig) => {
    try {
      const viewedCommentsIds = getConfig().comments.viewedCommentsIds[postID]
      const differenceViewedIds = viewedIds.filter((id) => !viewedCommentsIds?.includes(id))

      if (differenceViewedIds.length === 0) return
      const params = { [postID]: differenceViewedIds }
      await httpAuth.put(endpoints.updateViews, params)
    } catch (e) {
      console.log(e)
    }
  }
export const getCommentActivity =
  (oid: string): ThunkAction<any, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const { data } = await httpAuth.get(endpoints.getActivities(oid))
      dispatch(setCommentActivity({ oid, data }))
    } catch (e) {
      console.log(e)
    }
  }

export const updateLikes =
  (oid: string, uid: string): ThunkAction<any, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { likes } = getState().comments.commentsActivity[oid]
    if (likes[uid]) return
    try {
      dispatch(updateCommentsLikes({ oid, uids: [uid] }))
      const { data } = await httpAuth.put(endpoints.updateLikes, { oid })
      return data
    } catch (e) {
      console.log(e)
    }
  }
export const updateCommentViews =
  (oid: string, uid: string): ThunkAction<any, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { views } = getState().comments.commentsActivity[oid]
    if (views[uid]) return
    try {
      dispatch(updateCommentsViews({ oid, uids: [uid] }))
      const { data } = await httpAuth.put(endpoints.updateCommentViews, { oid })
      return data
    } catch (e) {
      console.log(e)
    }
  }

export const deleteCommensOfImage =
  (oid: string, parent_cid: string, comments_count_key: string): ThunkAction<any, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { commentsState, commentsCounter } = getState().comments
      const comments = commentsState[oid]?.items || []
      dispatch(
        setCommentsCounter({
          count: commentsCounter[comments_count_key] - comments.length,
          key: comments_count_key,
        })
      )

      const res = await httpAuth.delete(endpoints.deleteCommentsOfImage, { data: { parent_cid } })
      return !!res
    } catch (e) {
      console.log(e)
    }
  }
