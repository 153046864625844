// Description.tsx
import React, { useEffect } from 'react'
import { useMediaState } from 'hooks/useMediaState'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl } from '@chakra-ui/core'
import MediaGallery from '../../../../UI/MediaGallery'
import HiddenFileInput from '../../../../UI/HiddenInut'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import useLocaleText from 'components/useLocaleText'
import { useFormStepContext } from 'components/TasksV2/TaskCreation/FormStepContext'
import { AddMediaButtonts } from './AddMediaButtonts'
import DescriptionTextarea from './DescriptionTextarea'

const Description: React.FC = () => {
  const { getValues, register, setValue, control } = useFormContext()
  const { setIsMediaUploading } = useFormStepContext()
  const t_say_something = useLocaleText('t_say_something')

  const {
    uploadedImagesURL,
    uploadedVideosURL,
    uploadedFilesURL,
    handleMediaUploading,
    handleDeleteMedia,
    deleteFileHandler,
    handleFileUpload: uploadFile,
    imageInputRef,
    fileInputRef,
    isUploadLoading,
  } = useMediaState({
    images: getValues('description.attached_images') || [],
    files: getValues('description.attached_files') || [],
    videos: getValues('description.attached_videos') || [],
  })

  useEffect(() => {
    register('description.attached_images')
    register('description.attached_videos')
    register('description.attached_files')
  }, [register])

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = await handleMediaUploading(event)
    if (!data || (!data.uploadedImages.length && !data.uploadedVideos.length)) {
      return
    }

    if (data.uploadedImages.length) {
      const newImages = [...uploadedImagesURL, ...data.uploadedImages]
      setValue('description.attached_images', newImages)
    }
    if (data.uploadedVideos.length) {
      const newVideos = [...uploadedVideosURL, ...data.uploadedVideos]
      setValue('description.attached_videos', newVideos)
    }
  }

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = await uploadFile(event)
    if (!data || !data.fileName || !data.fileUrl) {
      return
    }
    const newFiles = [...uploadedFilesURL, data.fileUrl]
    setValue('description.attached_files', newFiles)
  }

  const handleRemoveMedia = (index: number) => {
    const { filteredImages, filteredVideos } = handleDeleteMedia(index)
    setValue('description.attached_videos', filteredVideos)
    setValue('description.attached_images', filteredImages)
  }

  const handleRemoveFile = (url: string) => {
    const updatedFiles = deleteFileHandler(url)
    setValue('description.attached_files', updatedFiles)
  }

  const handleMediaUploadClick = () => {
    imageInputRef.current?.click()
  }

  const handleFileUploadClick = () => {
    fileInputRef.current?.click()
  }
  useEffect(() => {
    setIsMediaUploading(isUploadLoading)
  }, [isUploadLoading, setIsMediaUploading])

  return (
    <FormControl mb={4} w="100%">
      <WidgetWrapper flexDir={'column'} pos="relative" p="15px">
        <Controller
          name="description.desc"
          control={control}
          as={DescriptionTextarea}
          value={getValues('description.desc') as string}
          onChange={([event]) => event.target.value}
          onBlur={([event]) => event.target.value}
          onImageUploadClick={handleMediaUploadClick}
          onFileUploadClick={handleFileUploadClick}
          placeholder={t_say_something}
        />
        {/* Media Gallery */}

        <MediaGallery
          attachedImages={uploadedImagesURL}
          attachedFiles={uploadedFilesURL}
          attachedVideos={uploadedVideosURL}
          onRemoveMedia={handleRemoveMedia}
          onRemoveFile={handleRemoveFile}
          isUploadLoading={isUploadLoading}
        />

        <AddMediaButtonts onFileUploadClick={handleFileUploadClick} onImageUploadClick={handleMediaUploadClick} />
        {/* Hidden File Inputs */}
        <HiddenFileInput accept="image/*, video/*" multiple onChange={handleImageUpload} ref={imageInputRef} />
        <HiddenFileInput accept="*/*" onChange={handleFileUpload} ref={fileInputRef} />
      </WidgetWrapper>
    </FormControl>
  )
}

export default Description
