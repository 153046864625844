import React, { useEffect, useState } from 'react'
import { Range, RangeFocus, RangeKeyDict } from 'react-date-range'
import { Button, Popover, PopoverTrigger, Text, useDisclosure } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { format, addDays, subDays } from 'date-fns'
import LocaleText from 'components/LocaleText'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangeContent } from './DateRangeContent'
import { stringTsToDate } from 'utils'
import colors from 'constants/colors'

const formatDate = (date: Date) => format(date, 'd LLL yyyy')

interface IProps {
  onSelectDate: (fromDate: Date, toDate: Date) => void
  onResetFilter: VoidFunction
  maxRange?: number
  openPopoverTriggerLiteral: string
  bgOfTriggerPopover?: string
  selectButtonLiteral?: string
  resetButtonLiteral?: string
  open?: boolean
  close?: () => void
  style?: any
  minDate?: Date
  maxDate?: Date
  initialValues?: string[] | null
  initilaDateValues?: Date[] | null
}

export const DateRangePopover = ({
  open,
  close,
  style,
  onSelectDate,
  onResetFilter,
  maxRange,
  bgOfTriggerPopover,
  openPopoverTriggerLiteral,
  resetButtonLiteral = 'archive_current_tasks',
  selectButtonLiteral = 'archive_select_date',
  maxDate,
  minDate,
  initialValues,
  initilaDateValues,
}: IProps) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const [isDefaultValueChanged, setIsDefaultValueChanged] = useState(false)
  const [maxDateRange, setMaxDateRange] = useState<undefined | Date>(undefined)
  const [minDateRange, setMinDateRange] = useState<undefined | Date>(undefined)
  const { activeGroupID } = useSelector((state: RootState) => state.config)
  const [state, setState] = useState<Range[]>([
    {
      startDate: minDate ? minDate : new Date(),

      endDate: maxDate ? maxDate : new Date(),
      key: 'selection',
    },
  ])

  useEffect(() => {
    if (initialValues && initialValues?.length > 0) {
      setState(() => [
        { ...state[0], startDate: stringTsToDate(initialValues[0]), endDate: stringTsToDate(initialValues[1]) },
      ])
    }
    if (initilaDateValues !== undefined) {
      if (initilaDateValues !== null) {
        setState(() => [{ ...state[0], startDate: initilaDateValues[0], endDate: initilaDateValues[1] }])
      } else {
        setState(() => [{ ...state[0], startDate: new Date(), endDate: new Date() }])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, initilaDateValues])

  const handleFilter = () => {
    onSelectDate(state[0].startDate!, state[0].endDate!)
    onClose()
    close?.()
  }

  const resetRange = () => {
    setMinDateRange(() => undefined)
    setMaxDateRange(() => undefined)
  }

  const resetRangeState = () => {
    setState(() => [
      { ...state[0], startDate: minDate ? minDate : new Date(), endDate: maxDate ? maxDate : new Date() },
    ])
    resetRange()
    setIsDefaultValueChanged(false)
    onClose()
    close?.()
  }

  const resetFilter = () => {
    onResetFilter()
    resetRangeState()
  }

  const rangeFocusChangeHandler = (focusedDates: RangeFocus) => {
    if (!focusedDates[0] && focusedDates[1]) {
      setState((prevState: Range[]) => [{ ...prevState[0], endDate: prevState[0].startDate }])
    }
    if (!focusedDates[0] && !focusedDates[1]) {
      resetRange()
    }
  }

  const changeDateHandler = (value: RangeKeyDict) => {
    const newRange = { ...value.selection }
    if (newRange?.endDate) {
      const end = new Date(newRange.endDate)
      end.setHours(23, 59, 59, 999)
      newRange.endDate = end
    }
    setState([newRange])

    setIsDefaultValueChanged(true)
  }

  useEffect(() => {
    resetRangeState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGroupID])

  useEffect(() => {
    if (isDefaultValueChanged && maxRange) {
      setMaxDateRange(() => addDays(state[0].startDate!, maxRange))
      setMinDateRange(() => subDays(state[0].startDate!, maxRange))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[0].startDate, isDefaultValueChanged])

  const popoverOpen = isOpen || open

  useEffect(() => {
    function clickOutside(e: any) {
      if (!e.target.closest('[role=dialog]')) {
        close?.()
        onClose()
      }
    }

    if (popoverOpen) {
      document.addEventListener('click', clickOutside)
    } else {
      document.removeEventListener('click', clickOutside)
    }

    return () => document.removeEventListener('click', clickOutside)
  }, [popoverOpen, close, onClose])

  const text =
    initilaDateValues && initilaDateValues?.length > 0
      ? `${formatDate(initilaDateValues[0])} - ${formatDate(initilaDateValues[1])}`
      : ''

  return (
    <Popover closeOnBlur={true} isOpen={popoverOpen}>
      {open === undefined && (
        <PopoverTrigger>
          <Button
            maxWidth="220px"
            onClick={onToggle}
            dir="ltr"
            borderRadius="15px"
            background={bgOfTriggerPopover}
            fontSize="16px"
            border="1px solid"
            borderColor={colors.greyLight}
            _focus={{ outline: 'none' }}
          >
            <Text>{text || <LocaleText text={openPopoverTriggerLiteral} />}</Text>
          </Button>
        </PopoverTrigger>
      )}
      <DateRangeContent
        style={style}
        onChange={changeDateHandler}
        ranges={state}
        className="byDateSelect"
        rangeFocusChange={rangeFocusChangeHandler}
        handleFilter={handleFilter}
        selectButtonLiteral={selectButtonLiteral}
        maxDateRange={maxDate ? maxDate : maxDateRange}
        minDateRange={minDate ? minDate : minDateRange}
        resetButtonLiteral={resetButtonLiteral}
        resetFilter={resetFilter}
      />
    </Popover>
  )
}
