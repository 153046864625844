/* eslint-disable no-prototype-builtins */
import { IConfigRetailUser, IConfigStateGroups, Results, Subtasks, Task } from 'constants/interfaces'
import keys from 'constants/keys'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { mapUsersByGroups } from 'redux/actions/config'
import { store } from '../../config/redux'

type GetTaskStatus = {
  subtasksLength: number
  resultsLength: number
}
export const getTaskStatus = ({ subtasksLength, resultsLength }: GetTaskStatus) => {
  const { INPROGRESS, TODO, DONE } = keys.TASK_STATUSES

  if (!resultsLength) {
    return TODO.value
  }

  return subtasksLength !== resultsLength ? INPROGRESS.value : DONE.value
}

export const formatRelativeDateTime = (timestamp: number): string => {
  const { translations } = store.getState().config
  const { locale } = store.getState().general
  dayjs.extend(relativeTime)
  dayjs.extend(advancedFormat)

  const date = dayjs(timestamp * 1000)
  const now = dayjs()

  let relativeDate: string

  if (date.isSame(now, 'day')) {
    relativeDate = 't_today'
  } else if (date.isSame(now.subtract(1, 'day'), 'day')) {
    relativeDate = 't_yesterday'
  } else if (date.isSame(now.subtract(2, 'days'), 'day')) {
    relativeDate = 't_2_days_ago'
  } else if (date.isSame(now, 'week')) {
    relativeDate = 't_this_week'
  } else if (date.isSame(now, 'month')) {
    relativeDate = 't_this_month'
  } else if (date.isSame(now, 'year')) {
    relativeDate = 't_this_year'
  } else if (date.isSame(now.subtract(1, 'year'), 'year')) {
    relativeDate = 't_year_ago'
  } else {
    relativeDate = date.format('YYYY')
  }

  const time = date.format('HH:mm')

  return `${translations[locale][relativeDate]} ${translations[locale]?.['t_at'] ?? 'at'} ${time}`
}

type GetAvatarSrc = { id: string; groups: IConfigStateGroups; retailUsersObject: { [key: string]: IConfigRetailUser } }

export const getAvatarSrc = ({ id, groups, retailUsersObject }: GetAvatarSrc) => {
  if (retailUsersObject?.[id]) {
    return retailUsersObject?.[id]?.profile_img_url
  } else {
    const group = { [id]: groups[id] }
    const groupManagerId = mapUsersByGroups({ groups: group, retailUsersObject: retailUsersObject! })?.[id]?.[0] ?? ''
    return retailUsersObject?.[groupManagerId]?.profile_img_url
  }
}

export const calculateTaskStatus = (task: Task, subtasks: Subtasks, results: Results) => {
  const { tid } = task
  const subtasksLength = subtasks?.[tid]?.length
  const resultsLength = results?.[tid]?.filter((result) => result.status === 2 || result.status === -1)?.length ?? 0
  return getTaskStatus({ subtasksLength, resultsLength })
}
