export default {
  LOGIN: 'Login',
  FORGOT_PW: 'Forgot Password?',
  errors: {
    USERNAME_AND_PW: 'Username and password you’ve entered doesn’t match any account.',
  },
  DEFAULT_MAX_GROUP_LEVEL: 0,

  DATE_FORMATS: {
    inputs: {
      taskPeriod: 'YYYYMMDDhhmmss',
    },
    outputs: {
      taskPeriod: 'DD/MM/YY',
      CREATED_AT_TS_FORMAT: 'DD/MM/YYYY HH:MM',
    },
  },
  COMPANY_NAME: 'STOREE',
  DEFAULT_PROFILE_IMAGE: 'https://i.ya-webdesign.com/images/empty-avatar-png.png',
  IMAGE_DATA_URI_PREFIX: 'data:image/jpg;base64,',
  GROUP_LEVEL: 'level',
  OVERVIEW_FILTER: {
    BY_ASSIGN: {
      ALL: {
        title: 'assigned_by_all_filter',
        value: 'assigned_by_all_filter',
      },
      ME: {
        title: 'assigned_by_me_filter',
        value: 'assigned_by_me_filter',
      },
    },
    BY_LEVEL: {
      GROUP: {
        title: 't_assign_to_groups',
        value: 1,
      },
      TAG: {
        title: 't_assign_to_tags',
        value: 0,
      },
      USER: {
        title: 't_assign_to_users',
        value: -1,
      },
      ALL: {
        title: 't_all_tasks',
        value: 100,
      },
    },
  },
  CONFIRM_MESSAGE: {
    confirmDeleteSubTask: 'Are you sure you want to delete?',
  },
  SERVER_IMAGE_FORMATS: '.jpeg,.jpg,.png',
  SERVER_VIDEO_FORMATS: '.m4v,.mp4,.mov',
  POST_FILE_ACCEPT_EXTENSIONS: '.docx,.dox,.jpeg,.jpg,.m4v,.mp4,.pdf,.png,.xls,.xlsx',
  MULTIPLE_MODE: 'multile_mode',
  PARTIAL_MULTILPE_MODE: 'partial_multiple_mode',
  USERS_MODE: 'users_mode',
  SINGLE_MODE_POSTS: 'single_mode_posts',
  SINGLE_MODE_CONTEXT: 'single_mode_context',
  CHILD_LEVEL_TAG: 'child_level_tag',
  RANK_DIFF_STRING: 'new',
}
