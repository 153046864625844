import { IConfigRetailUser, IConfigStateGroups, ITag, Result, Results, Task } from 'constants/interfaces'
import keys from 'constants/keys'
import dayjs from 'dayjs'
import { calculateResultContextID, DeepDiveInsights } from 'redux/actions/tasks_v2'
import { extractSubstringAfterDelimiter, getTokenFromCookie } from 'utils'

interface IBuildPollOptions {
  options: { [key: string]: string }
  pollResults: Result[]
  permittedUsers: string[]
  permittedGroups: IConfigStateGroups
  deepDiveInsights?: DeepDiveInsights
  groups: IConfigStateGroups
  retailUsersObject: { [key: string]: IConfigRetailUser }
}
export const buildPollOptions = ({
  options,
  pollResults,
  permittedUsers,
  permittedGroups,
  deepDiveInsights,
  groups,
  retailUsersObject,
}: IBuildPollOptions) => {
  return Object.entries(options).map(([key, label]) => {
    const currentQuestionResults = pollResults.filter(
      (result) =>
        result?.poll_selections?.includes(parseInt(key, 10)) &&
        (Object.keys(retailUsersObject).includes(result.context_id) || Object.keys(groups).includes(result.context_id))
    )

    const count = currentQuestionResults.length

    const currentQuestionParticipants = currentQuestionResults.map((result) => {
      return {
        id: result.context_id,
        date: result.updated_at_ts ? dayjs.unix(result.updated_at_ts).toISOString() : '',
        isClickable:
          permittedUsers.includes(result.context_id) || Object.keys(permittedGroups).includes(result.context_id),
        ...(deepDiveInsights ? { insights: deepDiveInsights?.insights?.[result.context_id] } : {}),
      }
    })

    return { label, count, currentQuestionParticipants }
  })
}

export const isExternalLink = (link: string) =>
  link.includes(keys.EXTERNAL_LINK_PHRASE) || link.includes(keys.FORMS_STOREE)

export const isSubtaskAlreadyProcessed = ({
  tid,
  st_id,
  contextId,
  userResults,
}: {
  tid: string
  st_id: string
  userResults: Results
  contextId?: string | null
}) => {
  const subtasks = userResults[tid]
  if (!subtasks) return false

  return subtasks.some((subtask) => {
    if (contextId) {
      return subtask.st_id === st_id && subtask.context_id === contextId
    }
    return subtask.st_id === st_id
  })
}
type getFormattedLinkProps = {
  link: string
  tasks: Task[]
  tid: string
  st_id: string
  contextId: string
  uid: string
  userResults: Results
  rid: string
  tags: ITag[]
  first_name: string
  last_name: string
  default_group: string | null
  groups: IConfigStateGroups
  activeGroupID: string
}
export const getFormattedLink = ({
  link,
  tasks,
  tid,
  st_id,
  contextId,
  uid,
  userResults,
  rid,
  tags,
  first_name,
  last_name,
  default_group,
  groups,
  activeGroupID,
}: getFormattedLinkProps) => {
  if (!isExternalLink(link)) {
    return link
  }
  const task = tasks.find((task) => task.tid === tid)
  if (!task) {
    throw new Error('Task not found')
  }
  const token = getTokenFromCookie()
  const version = 2
  const utc_offset = 3
  const { recipient_type, recurrence_start_time, is_individual_results, retail_id_context_id, recurrence_ts } = task
  const resultContextId = calculateResultContextID({
    recipient_type,
    is_individual_results,
    uid,
    groupId: activeGroupID,
    tagId: recipient_type === 2 ? extractSubstringAfterDelimiter(retail_id_context_id, rid) : null,
    retail_id_context_id,
    rid,
    tags,
  })
  const userResultOfCurrentSubtask = userResults?.[tid]?.find(
    (result) => result.st_id === st_id && result.context_id === contextId
  )
  const task_context_id = extractSubstringAfterDelimiter(task.retail_id_context_id, rid)

  const isFirstResult = !userResultOfCurrentSubtask
  const recurrenceTS = recurrence_ts

  const params = {
    token,
    task_id: tid,
    subtask_id: st_id,
    version,
    is_individual_results,
    context_id: resultContextId,
    is_first_result: isFirstResult,
    recurrence_ts: recurrenceTS,
    recurrence_start_time: recurrence_start_time,
    utc_offset,
    recipient_type,
    first_name: first_name,
    task_context_id,
    last_name: last_name ?? '',
    branch_name: default_group ? groups?.[default_group].name : '',
  }

  const queryString = Object.keys(params)
    .filter((key) => params[key] !== null && params[key] !== undefined) // Filter out null or undefined
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`) // Encode and map to key=value
    .join('&')

  return `${link}?${queryString}`
}
