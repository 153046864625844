import React, { memo, useCallback, useMemo } from 'react'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { StatusCircle } from 'components/TasksV2/UI/StatusCircle'
import { SubtaskTitile } from 'components/TasksV2/UI/SubtaskTitile'
import { useSelector } from 'react-redux'
import { Result, RootState, Subtask } from 'constants/interfaces'
import { useContextCommentsCounter } from 'components/TasksV2/hooks/useContexsCommentsCounter'
import { ConmmentsCount } from 'components/TasksV2/CommentsSection/ConmmentsCount'
import {
  TaskOverviewContentType,
  useTaskOverviewContext,
} from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'
import colors from 'constants/colors'

interface IProps {
  isSubtaskDone: boolean
  subtask: Subtask
  renderContent: JSX.Element
  subTaskResult?: Result
}

const BaseSubtask: React.FC<IProps> = memo(({ isSubtaskDone, subtask, renderContent, subTaskResult }) => {
  const { resultContextId, task, openContent, isReadMode } = useTaskOverviewContext()
  const { title } = subtask
  const { recurrence_ts } = task
  const contextIdRecurrenceTs = useMemo(
    () => `${resultContextId}${recurrence_ts ? '_' + recurrence_ts : ''}`,
    [recurrence_ts, resultContextId]
  )

  const oid = useMemo(() => `${subtask.st_id}_${contextIdRecurrenceTs}`, [contextIdRecurrenceTs, subtask.st_id])

  const { totalCommentsCount, loading } = useContextCommentsCounter([oid])
  const viewedCommentsIds = useSelector((state: RootState) => state.comments.viewedCommentsIds[oid])
  const unredCommentsCounter =
    totalCommentsCount - (viewedCommentsIds?.length || 0) >= 0
      ? totalCommentsCount - (viewedCommentsIds?.length || 0)
      : 0

  const isForbiddenToOpen = isReadMode && !subTaskResult
  const onSubTaskClick = useCallback(() => {
    if (!subtask || isForbiddenToOpen) {
      return
    }
    openContent(TaskOverviewContentType.MEDIA_SUBTASK_COMPLETING, { selectedSubtask: subtask })
  }, [isForbiddenToOpen, openContent, subtask])

  return (
    <WidgetWrapper cursor={isForbiddenToOpen ? 'default' : 'pointer'} p="15px" onClick={onSubTaskClick}>
      {renderContent}
      {isSubtaskDone && <StatusCircle color={colors.greenPoll} />}
      {subTaskResult && (
        <ConmmentsCount
          totalCommentsCount={totalCommentsCount}
          unredCommentsCounter={unredCommentsCounter}
          loading={loading}
        />
      )}

      <SubtaskTitile title={title} mt="35px" mb="25px" />
    </WidgetWrapper>
  )
})
export default BaseSubtask
