import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Skeleton,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/core'
import { AnimatedCircularProgress } from 'components/CommonComponents/AnimatedCircularProgress'
import { CustomSkeleton } from 'components/Elements'
import { calculateTaskStats, TaskStatusesBlocks } from 'components/TasksOverview/TaskStatusesBlocks'
import { ContextsList } from 'components/TasksV2/Insights/Content/ContextsList'
import { SearchInput } from 'components/TasksV2/Insights/Content/ContextsList/SearchInput'
import { InsightsModal } from 'components/TasksV2/Insights/InsightsModal'
import { InsightsContentType } from 'components/TasksV2/Insights/InsightsStackProvider'
import { getSubtasksByType } from 'components/TasksV2/Insights/utils'
import { TaskOverviewModal } from 'components/TasksV2/TasksOverview/TaskOverviewModal'
import {
  ITaskOverviewContentStack,
  TaskOverviewContentType,
} from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'
import useLocaleText from 'components/useLocaleText'
import { AppDispatch } from 'config/redux'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import { getContextName } from 'hooks/useContextName'
import { isEmpty } from 'lodash'
import { ChartNoAxesColumn, ChevronLeft, ChevronRight, Images } from 'lucide-react'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  getAllResults,
  getInsightsV2,
  getTask,
  getTasksInsights,
  setTasksDeepDiveInsights,
  SubtaskDeepDiveItem,
} from 'redux/actions/tasks_v2'
import { isRtlSelector } from 'redux/selectors/general'
import { getProgressPercentage } from 'utils'

const TaskInsights = () => {
  const dispatch: AppDispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const tid = searchParams.get('tid')
  const recurrenceTs = searchParams.get('recurrence_ts') ?? null
  const t_media_overview = useLocaleText('t_media_overview')

  const isRtl = useSelector(isRtlSelector)
  const {
    insights: allInsights,
    deepDiveInsights,
    taskFromInsights,
    isInsightsLoading,
  } = useSelector((state: RootState) => state.tasks_v2)
  const {
    config: { groups, retailUsersObject },
  } = useSelector((state: RootState) => state.config)
  const theme = useSelector((state: RootState) => state.general.theme)

  const { isOpen: isTaskOverviewOpen, onOpen: onOpenTaskOverview, onClose: onCloseTaskOverview } = useDisclosure()
  const { isOpen: isMediaGalleryOpen, onOpen: onOpenMediaGallery, onClose: onCloseMediaGallery } = useDisclosure()

  const [taskOverviewContent, setTaskOverviewContent] = useState<ITaskOverviewContentStack | null>(null)
  const [searchQuery, setSearchQuery] = useState('')

  const taskTitle = tid ? allInsights?.[tid]?.title : ''
  const tasksStatuses = useMemo(() => calculateTaskStats(deepDiveInsights?.contexts || {}), [deepDiveInsights])

  const sortedStatuses = useMemo(() => {
    const retailUserKeys = new Set(Object.keys(retailUsersObject!))
    const groupKeys = new Set(Object.keys(groups))

    return Object.entries(deepDiveInsights.contexts)
      .reduce((acc, [contextId, value]) => {
        if (retailUserKeys.has(contextId) || groupKeys.has(contextId)) {
          acc.push({
            contextId,
            status: value.status,
            taskContextId: value.taskContextId,
            name: getContextName(contextId, groups, retailUsersObject!),
          })
        }
        return acc
      }, [] as { contextId: string; status: number; taskContextId: string; name: string }[])
      .sort((a, b) => b.status - a.status)
  }, [deepDiveInsights.contexts, groups, retailUsersObject])

  const filteredStatuses = useMemo(() => {
    if (!searchQuery) return sortedStatuses
    return sortedStatuses.filter(({ name }) => name.toLowerCase().includes(searchQuery.toLowerCase()))
  }, [searchQuery, sortedStatuses])

  const handleOpenTaskOverview = useCallback(() => {
    setTaskOverviewContent(null)
    onOpenTaskOverview()
  }, [onOpenTaskOverview])

  const handleOpenVotesOverview = useCallback(
    (contentProps: any) => {
      setTaskOverviewContent({ contentType: TaskOverviewContentType.VOTES_OVERVIEW, props: contentProps })
      onOpenTaskOverview()
    },
    [onOpenTaskOverview]
  )

  const goBack = () => {
    dispatch(setTasksDeepDiveInsights({ contexts: {}, insights: {} }))
    navigate(keys.ROUTE_NAMES.DASHBOARD)
  }

  useEffect(() => {
    if (tid && !isEmpty(allInsights)) {
      dispatch(getTasksInsights({ tid, recurrence_ts: recurrenceTs ?? null }))
    }
  }, [allInsights, dispatch, recurrenceTs, tid])

  useEffect(() => {
    if (isEmpty(allInsights)) {
      dispatch(getInsightsV2({}))
    }
    // eslint-disable-next-line
  }, [])

  if (!tid) return null

  const { subtasks: mediaSubtasks } = getSubtasksByType({
    insights: deepDiveInsights.insights,
    allInsights,
    subtaskTypes: [keys.SUBTASK_TYPES.IMAGE_SUBTASK, keys.SUBTASK_TYPES.VIDEO_SUBTASKS],
    tid,
  })
  const { subtasks: pollSubtasks } = getSubtasksByType({
    insights: deepDiveInsights.insights,
    allInsights,
    subtaskTypes: [keys.SUBTASK_TYPES.POLL_SUBTASK],
    tid,
  })

  const isThereOverviewButtons = pollSubtasks.length > 0 || mediaSubtasks.length > 0

  return (
    <Flex position="relative" width="100%" height="calc(100vh - 90px)" pt="2.5rem" background="#fff">
      <Flex pl={isRtl ? 0 : '25px'} pr={isRtl ? '25px' : 0} flexDir="column" w={{ base: '100%', lg: '70%' }}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" style={{ columnGap: '10px' }}>
            <Box cursor="pointer" onClick={goBack}>
              {isRtl ? (
                <ChevronRight color={colors.greyMain} style={{ padding: '0' }} />
              ) : (
                <ChevronLeft color={colors.greyMain} style={{ padding: '0' }} />
              )}
            </Box>
            <Text>{taskTitle}</Text>
          </Flex>
          <SearchInput
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            border="1px solid #E5E5E5"
          />
        </Flex>

        <Flex position="relative" flexDir="column" alignItems="center">
          <TaskStatusesBlocks deepDiveInsights={deepDiveInsights} isShowIcons />
          <Flex alignItems="center" style={{ columnGap: '20px' }}>
            <PollsOverviewMenu
              tid={tid}
              pollSubtasks={pollSubtasks}
              recurrenceTs={recurrenceTs}
              handleOpenVotesOverview={handleOpenVotesOverview}
            />
            {isInsightsLoading ? (
              <Skeleton height="40px" width="200px" mb="15px" borderRadius="30px" />
            ) : (
              mediaSubtasks.length > 0 && (
                <Button
                  bg={theme?.elementsColor}
                  color="white"
                  borderRadius="30px"
                  style={{ columnGap: '10px' }}
                  mb="15px"
                  _hover={{ opacity: 0.6, bg: theme?.elementsColor }}
                  _focus={{ boxShadow: 'none' }}
                  onClick={onOpenMediaGallery}
                >
                  <Text>{t_media_overview}</Text>
                  <Images size={18} />
                </Button>
              )
            )}
          </Flex>
        </Flex>
        {isInsightsLoading || isEmpty(allInsights) || isEmpty(deepDiveInsights) ? (
          <Box>
            {Array.from({ length: 8 }).map((_, index) => (
              <CustomSkeleton key={index} mb="3" height="111px" width="100%" borderRadius="15px" />
            ))}
          </Box>
        ) : (
          <>
            {filteredStatuses.length > 0 && (
              <ContextsList
                onOpen={handleOpenTaskOverview}
                recurrenceTs={recurrenceTs}
                tid={tid}
                contexts={filteredStatuses}
                isThereOverviewButtons={isThereOverviewButtons}
              />
            )}
          </>
        )}
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        display={{ base: 'none', lg: 'flex' }}
        w={{ base: '100%', lg: '30%' }}
      >
        <AnimatedCircularProgress
          isLoading={isInsightsLoading}
          itemsCount={getProgressPercentage(tasksStatuses.totalTasks, tasksStatuses.statuses.done)}
          symbol="%"
          thinkness={0.02}
        />
      </Flex>

      {isTaskOverviewOpen && taskFromInsights && (
        <TaskOverviewModal
          onClose={onCloseTaskOverview}
          task={taskFromInsights}
          isComeFromDashboard
          {...(taskOverviewContent !== null && { content: taskOverviewContent })}
        />
      )}
      {isMediaGalleryOpen && tid && (
        <InsightsModal
          onClose={onCloseMediaGallery}
          tid={tid}
          selectedRecurrenceTs={recurrenceTs}
          content={{ contentType: InsightsContentType.ALL_CONTEXTS_MEDIA_RESULTS_PREVIEW }}
        />
      )}
    </Flex>
  )
}

export default TaskInsights

const PollsOverviewMenu: React.FC<{
  tid: string
  handleOpenVotesOverview: (contentProps: any) => void
  pollSubtasks: SubtaskDeepDiveItem[]
  recurrenceTs: string | null
}> = memo(({ tid, handleOpenVotesOverview, recurrenceTs, pollSubtasks }) => {
  const dispatch: AppDispatch = useDispatch()
  const { deepDiveInsights, isInsightsLoading } = useSelector((state: RootState) => state.tasks_v2)
  const isRtl = useSelector(isRtlSelector)
  const theme = useSelector((state: RootState) => state.general.theme)
  const [isFetching, setIsFetching] = useState(false)

  const isLoading = isInsightsLoading || isFetching

  const polls_overview = useLocaleText('polls_overview')

  const handleOptionClick = async (st_id: string) => {
    setIsFetching(true)
    try {
      const contextEntries = Object.entries(deepDiveInsights.contexts)
      const [resultContextId, { taskContextId }] = contextEntries[0]

      const taskResponse = await dispatch(
        getTask({
          tid,
          task_context_id: taskContextId,
          result_context_id: resultContextId,
          recurrence_ts: recurrenceTs,
        })
      )
      if (!taskResponse.task || !taskResponse.subtasks) {
        return
      }
      const { task, subtasks } = taskResponse

      const pollSubtask = subtasks.find((subtask) => subtask.st_id === st_id)
      if (!pollSubtask) {
        return
      }
      const results = await dispatch(getAllResults({ task, subtasksIds: [pollSubtask.st_id] }))
      if (results) {
        handleOpenVotesOverview({ selectedSubtask: pollSubtask, title: pollSubtask.title })
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <>
      {isInsightsLoading ? (
        <Skeleton height="40px" width="200px" mb="15px" borderRadius="30px" />
      ) : (
        pollSubtasks.length > 0 && (
          <>
            {pollSubtasks.length === 1 ? (
              <Button
                color="white"
                borderRadius="30px"
                mb="15px"
                bg={theme?.elementsColor}
                cursor={isLoading ? 'not-allowed' : 'pointer'}
                pointerEvents={isLoading ? 'none' : 'auto'}
                style={{ columnGap: '10px' }}
                opacity={isLoading ? 0.4 : 1}
                _hover={{ opacity: 0.6, bg: theme?.elementsColor }}
                _focus={{ boxShadow: 'none' }}
                onClick={() => handleOptionClick(pollSubtasks[0].st_id)}
              >
                <Text>{polls_overview}</Text>
                {isFetching ? <Spinner size="sm" /> : <ChartNoAxesColumn size={18} />}
              </Button>
            ) : (
              <Menu closeOnBlur>
                <MenuButton
                  as={Button}
                  color="white"
                  borderRadius="30px"
                  mb="15px"
                  bg={theme?.elementsColor}
                  cursor={isLoading ? 'not-allowed' : 'pointer'}
                  pointerEvents={isLoading ? 'none' : 'auto'}
                  style={{ columnGap: '10px' }}
                  opacity={isLoading ? 0.4 : 1}
                  _hover={{ opacity: 0.6, bg: theme?.elementsColor }}
                  _focus={{ boxShadow: 'none' }}
                >
                  <Text>{polls_overview}</Text>
                  {isFetching ? <Spinner w="14px" h="14px" /> : <ChartNoAxesColumn size={18} />}
                </MenuButton>
                <Portal>
                  <MenuList placement={isRtl ? 'bottom-end' : 'bottom-start'}>
                    {pollSubtasks.map((subtask) => (
                      <MenuItem key={subtask.st_id} onClick={() => handleOptionClick(subtask.st_id)}>
                        {subtask.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Portal>
              </Menu>
            )}
          </>
        )
      )}
    </>
  )
})
