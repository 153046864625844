/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ThemeProvider, CSSReset } from '@chakra-ui/core'
import customTheme from './theme'
import { RootState } from './constants/interfaces'
import { IntegrationGuard, RequireAuth, RequireMfa } from './routes'
import Auth from './services/authService'
import { jwtDecode } from 'jwt-decode'
import { Navigate } from 'react-router-dom'

import {
  handleConfig,
  handleInitialTranslations,
  handleUserWritePermitted,
  handleSetTaskPermittedGroup,
} from 'redux/actions/config'
import { handleTasks } from './redux/actions/tasks'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  LoadableNewsFeed,
  LoadableTask,
  LoadableTasksOverview,
  LoadableTasksOverviewDetail,
  LoadableTasksOverviewSubtasks,
  LoadableTasksList,
  LoadableLogin,
  LoadableCreateTask,
  LoadableTaskViews,
  LoadableRanking,
  LoadableIntegrations,
  LoadableImprovingSales,
  LoadableReports,
} from 'routes/loadableComponents'
import MainLoader from 'components/CommonComponents/MainLoader'
import keys from 'constants/keys'
import { Inactions } from 'components/Inactions'
import { ChangePassword } from 'pages/ChangePassword'
import { LoginConfirm } from 'pages/LoginConfirm'
import { isLoggedInSelector } from 'redux/selectors/auth'
import {
  LayoutWithCreateTaskSidebar,
  LayoutWithMainSidebar,
  LayoutWithTaskOverviewSidebar,
  LayoutWithTasksSidebar,
  LayoutWithoutSidebar,
} from 'components/CommonComponents/MainLayout'
import { GlobalStyles } from 'components/GlobalStyles'

import { ForgotPassword, ForgotPasswordSubmit } from 'pages'
import {
  getRanking,
  setRankingFilter,
  setRankingFilterList,
  setRankingReports,
  setRankingSort,
} from 'redux/actions/ranking'
import { handleGetPops } from 'redux/actions/pops'
import { logEvent, setUserProperties, setUserId as setAmplitudeUserId } from 'firebase/analytics'
import { setUserId } from 'redux/actions/auth'
import PopModal from 'components/CommonComponents/PopModal'
import { analytics } from './config/firebase'
import UserFormPage from 'components/AdminSettings/UserForm'
import UserTable from 'components/AdminSettings/UsersTable'
import ExternalSite from 'pages/ExternalSite'
import { ChatPage } from 'pages/ChatPage'
import GroupChat from 'pages/GroupChat'
import { GroupChatCommentPage } from 'pages/GroupChatCommentPage'
import { DeepDiveReport } from 'pages/DeepDiveReport'
import { getTasks } from 'redux/actions/tasks_v2'
import { ConfigErrorHandler } from 'components/CommonComponents/ConfigErrorHandler'
import { LoginSSO } from 'pages/LoginSSO'
import { getAWSTokenData, setToken } from 'redux/actions/auth'
import { FlashModal } from 'components/CommonComponents/FlashModal'
import { setResponseStatus } from 'redux/actions/general'
import TaskInsights from 'pages/TaskInsights'

function App({ code }: { code: string }) {
  const location = useLocation()
  const firstStage = process.env.REACT_APP_INACTION_FIRST_STAGE || keys.inactionsTime.firstStage
  const secondStage = process.env.REACT_APP_INACTION_SECOND_STAGE || keys.inactionsTime.secondStage
  const { uid } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(isLoggedInSelector)
  const [isSSOLoading, setIsSSOLoading] = useState(false)
  const { isResponseSuccess } = useSelector((state: RootState) => state.general)

  const {
    isConfigReady,
    retailConfig,
    activeGroupID,
    isConfigLoading,
    configErrorStatus,
    config: { rid },
  } = useSelector((state: RootState) => state.config)
  const { locale } = useSelector((state: RootState) => state.general)

  const resetStatus = useCallback(() => {
    dispatch(setResponseStatus(null))
  }, [dispatch])

  const fetchConfig = useCallback(() => {
    try {
      dispatch(handleConfig())
      dispatch(handleSetTaskPermittedGroup())
      dispatch(handleUserWritePermitted())
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isLoggedIn) {
      fetchConfig()
    } else {
      dispatch(handleInitialTranslations())
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isConfigReady) {
      dispatch(handleGetPops())
      dispatch(getTasks())
      dispatch(handleTasks())
    }
  }, [isConfigReady])

  useEffect(() => {
    if (isConfigReady && activeGroupID !== null) {
      const rankingTags = retailConfig?.ranking_tags

      dispatch(setRankingReports({}))
      dispatch(setRankingFilterList([]))
      dispatch(
        getRanking({
          firstRequest: true,
          kpis_data: true,
          kpis_list: true,
          rankingTag: rankingTags?.[0],
          ...(retailConfig?.data_filters &&
          Array.isArray(retailConfig?.data_filters) &&
          retailConfig?.data_filters.length > 0
            ? { dataFilter: retailConfig?.data_filters[0].values[0] }
            : {}),
        })
      )
    }
  }, [isConfigReady, retailConfig?.ranking_types])

  useEffect(() => {
    if (!location.pathname.includes('ranking')) {
      dispatch(setRankingSort(1))
      dispatch(setRankingFilter(keys.RANKING_DEFAULT_FILTER_VIEW))
    }
  }, [dispatch, location.pathname])

  useEffect(() => {
    logEvent(analytics, 'storee_screen_view', { currentScreen: location.pathname })
  }, [location])

  useEffect(() => {
    if (activeGroupID !== null && rid !== '' && uid) {
      setUserProperties(analytics, { storee_user_id: uid })
      setUserProperties(analytics, { retail_id: rid })
      setAmplitudeUserId(analytics, uid)
    }
  }, [activeGroupID, rid])
  const hasValidConfig = isConfigReady && !isConfigLoading

  const ssoSignIn = useCallback(async () => {
    if (code) {
      setIsSSOLoading(true)
      const tokensObject = await getAWSTokenData(code)

      if (tokensObject) {
        try {
          await Auth.handleAuthenticatedUserManually(tokensObject)
          const userId = jwtDecode(tokensObject.id_token)?.['cognito:username']
          dispatch(setToken(tokensObject.id_token))
          dispatch(setUserId(userId))
        } catch (err) {
          console.log(err)
        } finally {
          setIsSSOLoading(false)
        }
      }
    }
  }, [code, dispatch])

  useEffect(() => {
    ssoSignIn()
  }, [ssoSignIn])

  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <GlobalStyles />
      <Helmet>
        <html lang={locale} dir={locale === 'he' ? 'rtl' : 'ltr'} />
      </Helmet>

      <MainLoader isShow={(isConfigLoading && !!isLoggedIn) || isSSOLoading} />

      {!hasValidConfig && !!isLoggedIn && configErrorStatus && <ConfigErrorHandler status={configErrorStatus} />}
      {isResponseSuccess && (
        <FlashModal
          status={isResponseSuccess}
          resetStatus={resetStatus}
          text={isResponseSuccess ? 'post_published_successfully' : 'Something went wrong'}
        />
      )}
      <>
        <PopModal />
        <Routes>
          <Route path={keys.ROUTE_NAMES.LOGIN_PAGE} element={<LoadableLogin />} />
          <Route
            path={keys.ROUTE_NAMES.LOGIN_CONFIRM}
            element={
              <RequireMfa>
                <LoginConfirm />
              </RequireMfa>
            }
          />
          <Route
            path={keys.ROUTE_NAMES.CHANGE_PASSWORD}
            element={
              <RequireMfa>
                <ChangePassword />
              </RequireMfa>
            }
          />
          <Route path={keys.ROUTE_NAMES.LOGIN_SSO} element={<LoginSSO />} />
          <Route path={keys.ROUTE_NAMES.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={keys.ROUTE_NAMES.FORGOT_PASSWORD_SUBMIT} element={<ForgotPasswordSubmit />} />

          <Route path="/" element={<LayoutWithMainSidebar />}>
            <Route
              path={keys.ROUTE_NAMES.NEWS_FEED}
              element={
                <RequireAuth>
                  <LoadableNewsFeed />
                </RequireAuth>
              }
            />
            <Route
              path={keys.ROUTE_NAMES.NEWS_FEED_SEARCH}
              element={
                <RequireAuth>
                  <LoadableNewsFeed />
                </RequireAuth>
              }
            />
            <Route
              path={keys.ROUTE_NAMES.RANKING()}
              element={
                <RequireAuth>
                  <LoadableRanking />
                </RequireAuth>
              }
            />

            <Route
              path={keys.ROUTE_NAMES.INTEGRATIONS}
              element={
                <RequireAuth>
                  <IntegrationGuard>
                    <LoadableIntegrations />
                  </IntegrationGuard>
                </RequireAuth>
              }
            />
            <Route
              path={keys.ROUTE_NAMES.DASHBOARD}
              element={
                <RequireAuth>
                  <LoadableTasksOverview />
                </RequireAuth>
              }
            />
            <Route
              path={keys.ROUTE_NAMES.TASKS_CONTEXTS_LIST}
              element={
                <RequireAuth>
                  <TaskInsights />
                </RequireAuth>
              }
            />

            <Route
              path={`${keys.ROUTE_NAMES.REPORTS}/:targetGroupId/`}
              element={
                <RequireAuth>
                  <LoadableReports />
                </RequireAuth>
              }
            />
            <Route
              path={`${keys.ROUTE_NAMES.REPORTS}/:targetGroupId/:range_type/:component_Id/:sub_component_id/:component_filter`}
              element={
                <RequireAuth>
                  <DeepDiveReport />
                </RequireAuth>
              }
            />

            <Route path={keys.ROUTE_NAMES.TASKS}>
              <Route
                index
                element={
                  <RequireAuth>
                    <LoadableTaskViews />
                  </RequireAuth>
                }
              />
              <Route
                path=":viewId"
                element={
                  <RequireAuth>
                    <LoadableTasksList />
                  </RequireAuth>
                }
              />
            </Route>

            <Route
              path={keys.ROUTE_NAMES.EXTERNAL_SITE}
              element={
                <RequireAuth>
                  <ExternalSite />
                </RequireAuth>
              }
            />
          </Route>
          <Route path={keys.ROUTE_NAMES.DASHBOARD_DETAIL()} element={<LayoutWithTaskOverviewSidebar />}>
            <Route
              path={keys.ROUTE_NAMES.DASHBOARD_DETAIL()}
              element={
                <RequireAuth>
                  <LoadableTasksOverviewDetail />
                </RequireAuth>
              }
            />
            <Route
              path={keys.ROUTE_NAMES.DASHBOARD_SUBTASKS()}
              element={
                <RequireAuth>
                  <LoadableTasksOverviewSubtasks />
                </RequireAuth>
              }
            />
          </Route>
          <Route path={keys.ROUTE_NAMES.CREATE_TASK} element={<LayoutWithCreateTaskSidebar />}>
            <Route
              path={keys.ROUTE_NAMES.CREATE_TASK}
              element={
                <RequireAuth>
                  <LoadableCreateTask />
                </RequireAuth>
              }
            />
          </Route>
          <Route path={keys.ROUTE_NAMES.SUBTASKS()} element={<LayoutWithTasksSidebar />}>
            <Route
              index
              element={
                <RequireAuth>
                  <LoadableTask />
                </RequireAuth>
              }
            />
          </Route>
          <Route path={keys.ROUTE_NAMES.IMPROVING_SALES_DETAIL()} element={<LayoutWithTasksSidebar />}>
            <Route
              index
              element={
                <RequireAuth>
                  <LoadableTask />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="*" element={<Navigate to={keys.ROUTE_NAMES.NEWS_FEED} />} />
          <Route path={keys.ROUTE_NAMES.CHAT_PAGE} element={<LayoutWithoutSidebar />}>
            <Route
              path={keys.ROUTE_NAMES.CHAT_PAGE}
              element={
                <RequireAuth>
                  <ChatPage />
                </RequireAuth>
              }
            />
          </Route>
          <Route path={keys.ROUTE_NAMES.IMPROVING_SALES} element={<LayoutWithoutSidebar />}>
            <Route
              path={keys.ROUTE_NAMES.IMPROVING_SALES}
              element={
                <RequireAuth>
                  <LoadableImprovingSales />
                </RequireAuth>
              }
            />
          </Route>
          <Route path={keys.ROUTE_NAMES.GROUP_CHAT()} element={<LayoutWithoutSidebar />}>
            <Route
              path={keys.ROUTE_NAMES.GROUP_CHAT()}
              element={
                <RequireAuth>
                  <GroupChat />
                </RequireAuth>
              }
            />
          </Route>
          <Route path={keys.ROUTE_NAMES.GROUP_CHAT_ANNOUNCEMENT()} element={<LayoutWithoutSidebar />}>
            <Route
              path={keys.ROUTE_NAMES.GROUP_CHAT_ANNOUNCEMENT()}
              element={
                <RequireAuth>
                  <GroupChatCommentPage />
                </RequireAuth>
              }
            />
          </Route>

          <Route
            path={keys.ROUTE_NAMES.ADMIN_SETTINGS}
            element={
              <RequireAuth>
                <LayoutWithoutSidebar />
              </RequireAuth>
            }
          >
            <Route path={keys.ROUTE_NAMES.USERS_TABLE} element={<UserTable />} />
            <Route path={keys.ROUTE_NAMES.ADD_USER} element={<UserFormPage />} />
            <Route path={keys.ROUTE_NAMES.EDIT_USER()} element={<UserFormPage />} />
          </Route>
        </Routes>
      </>

      <Inactions
        firstStageTime={+firstStage}
        secondStageTime={+secondStage}
        intervalTime={+secondStage - +firstStage}
      />
    </ThemeProvider>
  )
}

export default App
