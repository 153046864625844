import React, { useMemo } from 'react'
import { isNumber } from 'lodash'
import { Box, Menu, MenuButton, MenuItem, MenuList, Image, Flex } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import { useSelector } from 'react-redux'
import { taskViewsSelector } from 'redux/selectors/config'
import { ErrorMessage } from 'components/TasksV2/UI/ErrorMessage'
import { Controller, useFormContext } from 'react-hook-form'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { FormInputLabel } from 'components/TasksV2/UI/FormInputLabel'

const ViewIcon = ({ icon }: { icon: string }) => {
  return (
    <Box borderRadius="10px" border="1px solid black" p="5px">
      <Image src={icon} w="20px" h="20px" />
    </Box>
  )
}
const TaskTypeSelection = () => {
  const { errors, control, watch } = useFormContext()

  const t_choose_task_view = useLocaleText('t_choose_task_view')
  const taskViews = useSelector(taskViewsSelector)
  const sortedTaskViews = taskViews?.sort((type, nextType) => type.order - nextType.order) ?? []

  const view = watch('view')

  const foundTaskView = useMemo(
    () =>
      sortedTaskViews.find((taskView) => {
        const taskViewId = taskView.task_view_id
        return taskViewId === view
      }),
    [sortedTaskViews, view]
  )

  return (
    <Controller
      name="view"
      control={control}
      rules={{
        validate: (value) => isNumber(value) || 'Please select a task type.',
      }}
      as={
        <Box w="100%">
          <FormInputLabel label={t_choose_task_view} />
          <Flex alignItems="center" style={{ columnGap: '10px' }} w="max-content" flexDir="row-reverse">
            <Menu>
              <MenuButton as={Flex} w="100%" style={{ columnGap: '10px' }} alignItems="center">
                <WidgetWrapper p="10px" cursor="pointer" isError={!!errors.view} w="100%">
                  <Flex
                    alignItems="center"
                    minW="200px"
                    flexDir="row"
                    justifyContent="flex-start"
                    style={{ columnGap: '10px' }}
                  >
                    {foundTaskView?.icon && <ViewIcon icon={foundTaskView.icon} />}
                    <LocaleText text={foundTaskView?.literal || foundTaskView?.task_view_name || t_choose_task_view} />
                  </Flex>
                </WidgetWrapper>
              </MenuButton>
              <MenuList
                h="500px"
                overflowY="auto"
                fontSize="14px"
                fontWeight="500"
                borderRadius="10px"
                placement="bottom-start"
              >
                {sortedTaskViews.map((type) => (
                  <MenuItem
                    key={type.task_view_id}
                    onClick={() => control.setValue('view', type.task_view_id)}
                    py="10px"
                    style={{ columnGap: '10px' }}
                  >
                    <ViewIcon icon={type.icon} />
                    <LocaleText text={type.literal || type.task_view_name || t_choose_task_view} />
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>

          {errors.view && (
            <Box px="10px" pt="5px">
              <ErrorMessage errorMessage="Please select a task type." />
            </Box>
          )}
        </Box>
      }
    />
  )
}

export default TaskTypeSelection
